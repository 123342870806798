import React, { useEffect, useRef, useState } from "react";
import { Paper } from "@material-ui/core";
import { baseUrl } from "../../../services/config";
import ReactToPrint from "react-to-print";
import "./monthypay.css";
import { Spinner, Table } from "react-bootstrap";
import { useAlert } from "react-alert";
import { upperCase } from "lodash";
import { useSelector } from "react-redux";
import { DownloadTableExcel } from "react-export-table-to-excel";

const MonthlyPaySheet = () => {
  // const loginAccount = JSON.parse(localStorage.getItem("userDetails")).role[0];
  const alert = useAlert();
  let authtoken = localStorage.getItem("userToken");
  const pageStyle = `
  @page {
margin-left: 1.5in;
    size: legal landscape !important;
}
`;
  const [month, setMonth] = useState("");
  const user = useSelector((state) => state.user.user);
  const [sessionData, setSessionData] = useState([]);
  const [yearId, setYearId] = useState("");
  const [campusData, setCampusData] = useState(user.campuses || []);
  const [campus, setCampus] = useState([]);
  const [reportData, setReportData] = useState([]);
  const [fullCampus, setFullCampus] = useState({});
  const [loading, setloading] = useState(false);
  const [selectType, setSelectType] = useState(1);
  const [selectGroup, setSelectGroup] = useState(0);
  const [glossTotal, setGlossTotal] = useState("");
  const [netTotal, setNetTotal] = useState("");
  const [printloading, setprintloading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");
  const componentRef = useRef(null);
  const tableRef = useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);

  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);

  useEffect(() => {
    sessions();
    getSettingData();
  }, []);

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setUnitName(upperCase(data.payload.settings.unit_name));
        setIcon(data.payload.settings.logo_file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const sessions = () => {
    fetch(`${baseUrl}/session`, {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setSessionData(data.payload);
        data.payload.forEach((session, index) => {
          if (session.active_financial_year == "1") {
            setYearId(session.id);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //for print
  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setprintloading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setprintloading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setprintloading, setText]);

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);
  const onShow = () => {
    if (month == "" || campus == "") {
      alert.error("Please Select All Fields");
      return;
    }

    let data = {
      date: `${month}-01`,
      year_id: yearId,
      campus_id: campus,
      bank_id: selectType,
      designation_id: selectGroup,
    };
    setloading(true);

    let cmapusName = campusData.find((item) => item.id == campus);
    setFullCampus(cmapusName);

    fetch(`${baseUrl}/monthly-pay-sheet-details`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) =>
        res.json().then((data) => {
          setloading(false);
          if (data.metadata) {
            if (data.metadata.success) {
              let sum = 0;
              data.payload.map((item) => {
                sum += parseInt(item?.gross_salary);
              });
              setGlossTotal(sum);
              let sum1 = 0;
              data.payload.map((item) => {
                sum1 += parseInt(item?.net_pay);
              });
              setNetTotal(sum1);
              console.log(data.payload);
              setReportData(data.payload);
            } else if (!data.metadata.success) {
              alert.error(data.metadata.message);
            }
          }
        })
      )

      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };
  function formatDate(inputDate) {
    const parts = inputDate.split("-");
    const month = parseInt(parts[1], 10);
    const year = parts[0];

    // Create an array of month names
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Get the full month name
    const fullMonth = monthNames[month - 1];

    // Format the result as "Month, Year"
    const formattedDate = `${fullMonth}, ${year}`;

    return formattedDate;
  }

  return (
    <>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        <div className="row">
          <div className="col-md-3">
            <label>
              <b>Select Session</b> <span style={{ color: "red" }}>*</span>
            </label>
            <div className="d-flex flex-column ">
              <select
                style={{ height: 40 }}
                className="form-select"
                name="year_id"
                value={yearId}
                aria-label="Default select example"
                onChange={(e) => setYearId(e.target.value)}
              >
                <option value="">Select Session</option>
                {sessionData &&
                  sessionData.map((item) => (
                    <option value={item.id}>{item.year}</option>
                  ))}
              </select>
            </div>
          </div>
          <div className="col-md-3">
            <label>
              <b>Select Campus</b> <span style={{ color: "red" }}>*</span>
            </label>
            <div className="d-flex flex-column">
              <select
                style={{ height: 40 }}
                className="form-select"
                aria-label="Default select example"
                onChange={(e) => {
                  setCampus(e.target.value);
                }}
              >
                <option selected value="">
                  {" "}
                  Select Campus
                </option>
                {campusData &&
                  campusData.map((item) => (
                    <option value={item.id}>{item.name}</option>
                  ))}
              </select>
            </div>
          </div>
          <div className="col-md-3">
            <label>
              <b>Select Type</b> <span style={{ color: "red" }}>*</span>
            </label>
            <div className="d-flex flex-column">
              <select
                style={{ height: 40 }}
                className="form-select"
                defaultValue={1}
                aria-label="Default select example"
                onChange={(e) => setSelectType(e.target.value)}
              >
                <option value={1}>Bank</option>
                <option value={2}>Cash</option>
              </select>
            </div>
          </div>
          <div className="col-md-3">
            <label>
              <b>Select Type</b> <span style={{ color: "red" }}>*</span>
            </label>
            <div className="d-flex flex-column">
              <select
                style={{ height: 40 }}
                className="form-select"
                defaultValue={0}
                aria-label="Default select example"
                onChange={(e) => setSelectGroup(e.target.value)}
              >
                <option value={0}>All Staff</option>
                <option value={7}>Class IV</option>
              </select>
            </div>
          </div>

          <div className="col-md-3 mt-3">
            <div>
              <label>
                <b>Select Month</b>
              </label>
            </div>
            <input
              value={month}
              onChange={(e) => {
                setMonth(e.target.value);
              }}
              className="form-control"
              type="month"
            />
          </div>
          <div className="col-md-4 mt-13">
            <button className="btn btn-primary mx-2" onClick={onShow}>
              Show
            </button>
            {reportData.length > 0 && (
              <>
                <ReactToPrint
                  pageStyle={pageStyle}
                  documentTitle={`Pay Salary`}
                  onAfterPrint={handleAfterPrint}
                  onBeforeGetContent={handleOnBeforeGetContent}
                  onBeforePrint={handleBeforePrint}
                  removeAfterPrint={true}
                  trigger={() => (
                    <button
                      disabled={printloading ? true : false}
                      className="btn btn-primary w-100px mx-2"
                    >
                      {printloading ? <span>Printing..</span> : "Print"}
                    </button>
                  )}
                  content={() => componentRef.current}
                />

                <DownloadTableExcel
                  filename="Monthly Pay Sheet"
                  sheet="MonthlyPaySheet"
                  currentTableRef={tableRef.current}
                >
                  <button className="btn btn-primary mx-2"> Export </button>
                </DownloadTableExcel>
              </>
            )}
          </div>
        </div>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner
              className="text-center"
              variant="info"
              animation="border"
            />
          </div>
        ) : (
          <div className="makeITScrollAAble">
            <Table
              style={{ width: "300%", marginTop: "10px", overflowY: "scroll" }}
              responsive
            >
              <thead>
                <tr id="tbl" style={{ position: "relative" }}>
                  <th className="text-center"></th>
                  <th className="text-center"></th>
                  <th className="text-center"></th>
                  <th className="text-center"></th>
                  <th className="text-center"></th>
                  <th className="text-center"></th>
                  <th className="text-center"></th>
                  <th className="text-center"></th>
                  <th className="text-center"></th>
                  <th className="text-center"></th>
                  <th className="text-center"></th>
                  <th
                    className="text-center text-primary"
                    style={{
                      position: "absolute",
                      width: "550px",
                      right: "45%",
                      bottom: "-18%",
                      borderBottom: "2px solid purple",
                    }}
                  >
                    ALLOWANCES
                  </th>
                  <th className="text-center"></th>
                  <th className="text-center"></th>
                  <th className="text-center"></th>
                  <th className="text-center"></th>
                  <th className="text-center"></th>
                  <th className="text-center"></th>
                  <th className="text-center"></th>
                  <th className="text-center"></th>
                  <th className="text-center"></th>
                  <th className="text-center"></th>
                  <th className="text-center"></th>
                  <th className="text-center"></th>
                  <th className="text-center"></th>
                  <th className="text-center"></th>
                  <th className="text-center"></th>
                  <th className="text-center"></th>
                  <th
                    className="text-center text-primary"
                    style={{
                      position: "absolute",
                      width: "550px",
                      right: "5%",
                      bottom: "-18%",
                      borderBottom: "2px solid purple",
                    }}
                  >
                    DEDUCTIONS
                  </th>
                  <th className="text-center"></th>
                  <th className="text-center"></th>
                  <th className="text-center"></th>
                </tr>
                <tr
                  id="tbl"
                  style={{
                    position: "sticky",
                    top: "0",
                    zIndex: "2",
                    backgroundColor: "gainsboro",
                  }}
                >
                  <th
                    className="text-center"
                    style={{
                      position: "sticky",
                      left: "0",
                      zIndex: "2",
                      backgroundColor: "gainsboro",
                    }}
                  >
                    Sr.No
                  </th>
                  <th
                    className="text-center"
                    style={{
                      position: "sticky",
                      left: "55px",
                      zIndex: "2",
                      backgroundColor: "gainsboro",
                    }}
                  >
                    Code
                  </th>
                  <th
                    className="text-center"
                    style={{
                      position: "sticky",
                      left: "110px",
                      zIndex: "2",
                      backgroundColor: "gainsboro",
                    }}
                  >
                    Name
                  </th>
                  <th className="text-center">Salary Days</th>
                  <th className="text-center">Pay Scale</th>
                  <th className="text-center">Basic Pay</th>
                  <th className="text-center">Voucher</th>
                  <th className="text-center">Increment Total</th>
                  <th className="text-center">Eobi</th>
                  <th className="text-center">Eobi Payment</th>
                  <th className="text-center">UGS Allowance</th>
                  <th className="text-center">Additional Allowance</th>
                  <th className="text-center">Extra Period Allowance</th>
                  <th className="text-center">Extra Coaching</th>
                  <th className="text-center">2nd Shift Allowance</th>
                  <th className="text-center">HOD Allowance</th>
                  <th className="text-center">College Allowance</th>
                  <th className="text-center">Hostel Allowance</th>
                  <th className="text-center">Hifz Allowance</th>
                  <th className="text-center">Other Allowance</th>
                  <th className="text-center">Conv Allow</th>
                  <th className="text-center">Science</th>
                  <th className="text-center">GPF Return</th>
                  <th className="text-center">GP Contribution</th>
                  <th
                    className="text-center"
                    style={{
                      borderLeft: "1px solid black",
                      borderRight: "1px solid black",
                    }}
                  >
                    Gross Salary
                  </th>
                  <th className="text-center">GP Fund</th>
                  <th className="text-center">Loan Refund</th>
                  <th className="text-center">Insu Dedu</th>
                  <th className="text-center">Income Tax</th>
                  <th className="text-center">Staff Van Dedu</th>
                  <th className="text-center">Child Fee Dedu</th>
                  <th className="text-center">Welfare Fund</th>
                  <th className="text-center">Other Deduction</th>
                  <th className="text-center">Net Salary</th>
                </tr>
              </thead>

              <tbody>
                {reportData.length > 0 &&
                  reportData.map((subitem, index) => (
                    <tr key={index} id="tbl">
                      <td
                        style={{
                          color: "black",
                          position: "sticky",
                          left: "0",
                          zIndex: "1",
                          backgroundColor: "white",
                        }}
                        className="text_align_center"
                      >
                        {index + 1}
                      </td>
                      <td
                        style={{
                          color: "black",
                          position: "sticky",
                          left: "55px",
                          zIndex: "1",
                          backgroundColor: "white",
                        }}
                        className="text_align_center"
                      >
                        {subitem.employee.emp_code}
                      </td>
                      <td
                        style={{
                          color: "black",
                          position: "sticky",
                          left: "110px",
                          zIndex: "1",
                          backgroundColor: "white",
                        }}
                        className="text_align_center"
                      >
                        {subitem.employee.full_name}
                      </td>
                      <td
                        style={{ color: "black" }}
                        className="text_align_center"
                      >
                        {subitem.days}
                      </td>
                      <td
                        style={{ color: "black" }}
                        className="text_align_center"
                      >
                        {subitem.employee.pay_scale.payscale}
                      </td>
                      <td
                        style={{ color: "black" }}
                        className="text_align_center"
                      >
                        {subitem.basic_pay}
                      </td>
                      <td
                        style={{ color: "black" }}
                        className="text_align_center"
                      >
                        {subitem.voucher?.voucher_no}
                      </td>
                      <td
                        style={{ color: "black" }}
                        className="text_align_center"
                      >
                        {subitem.increment}
                      </td>
                      <td
                        style={{ color: "black" }}
                        className="text_align_center"
                      >
                        {subitem.eobi}
                      </td>
                      <td
                        style={{ color: "black" }}
                        className="text_align_center"
                      >
                        {subitem.eobi_payments}
                      </td>
                      <td
                        style={{ color: "black" }}
                        className="text_align_center"
                      >
                        {subitem.ugs}
                      </td>
                      <td
                        style={{ color: "black" }}
                        className="text_align_center"
                      >
                        {subitem.additional_allowance}
                      </td>
                      <td
                        style={{ color: "black" }}
                        className="text_align_center"
                      >
                        {subitem.extra_period}
                      </td>
                      <td
                        style={{ color: "black" }}
                        className="text_align_center"
                      >
                        {subitem.extra_coaching}
                      </td>
                      <td
                        style={{ color: "black" }}
                        className="text_align_center"
                      >
                        {subitem.second_shift}
                      </td>
                      <td
                        style={{ color: "black" }}
                        className="text_align_center"
                      >
                        {subitem.hod}
                      </td>
                      <td
                        style={{ color: "black" }}
                        className="text_align_center"
                      >
                        {subitem.college}
                      </td>
                      <td
                        style={{ color: "black" }}
                        className="text_align_center"
                      >
                        {subitem.hostel}
                      </td>
                      <td
                        style={{ color: "black" }}
                        className="text_align_center"
                      >
                        {subitem.hifz}
                      </td>
                      <td
                        style={{ color: "black" }}
                        className="text_align_center"
                      >
                        {subitem.other_allowance}
                      </td>
                      <td
                        style={{ color: "black" }}
                        className="text_align_center"
                      >
                        {subitem.convance}
                      </td>
                      <td
                        style={{ color: "black" }}
                        className="text_align_center"
                      >
                        {subitem.science}
                      </td>
                      <td
                        style={{ color: "black" }}
                        className="text_align_center"
                      >
                        {subitem.gpf_return}
                      </td>
                      <td
                        style={{ color: "black" }}
                        className="text_align_center"
                      >
                        {subitem.gpf_cont}
                      </td>
                      {/* <td
                        style={{
                          color: "black",
                          borderLeft: "1px solid black",
                          borderRight: "1px solid black",
                          backgroundColor: "beige",
                        }}
                        className="text_align_center"
                      >
                        {Number(subitem.gross_salary)}
                      </td> */}
                      <td
                        style={{
                          color: "black",
                          borderLeft: "1px solid black",
                          borderRight: "1px solid black",
                          backgroundColor: "beige",
                        }}
                        className="text_align_center"
                      >
                        {Number(subitem.gross_salary)}
                      </td>
                      <td
                        style={{ color: "black" }}
                        className="text_align_center"
                      >
                        {subitem.gp_fund}
                      </td>
                      <td
                        style={{ color: "black" }}
                        className="text_align_center"
                      >
                        {subitem.loan_refund}
                      </td>
                      <td
                        style={{ color: "black" }}
                        className="text_align_center"
                      >
                        {subitem.insurance}
                      </td>
                      <td
                        style={{ color: "black" }}
                        className="text_align_center"
                      >
                        {subitem.income_tax}
                      </td>
                      <td
                        style={{ color: "black" }}
                        className="text_align_center"
                      >
                        {subitem.van_charge}
                      </td>
                      <td
                        style={{ color: "black" }}
                        className="text_align_center"
                      >
                        {subitem.child_fee_deduction}
                      </td>
                      <td
                        style={{ color: "black" }}
                        className="text_align_center"
                      >
                        {subitem.welfare_fund}
                      </td>
                      <td
                        style={{ color: "black" }}
                        className="text_align_center"
                      >
                        {subitem.other_deduction}
                      </td>
                      <td
                        style={{ color: "black" }}
                        className="text_align_center"
                      >
                        {Number(subitem.net_pay)}
                      </td>
                    </tr>
                  ))}

                {reportData.length > 0 && (
                  <tr id="tbl">
                    <td
                      style={{
                        color: "black",
                        position: "sticky",
                        left: "0",
                        zIndex: "1",
                        backgroundColor: "white",
                        fontWeight: "bold",
                      }}
                      className="text_align_center"
                    >
                      {"-"}
                    </td>
                    <td
                      style={{
                        color: "black",
                        position: "sticky",
                        left: "55px",
                        zIndex: "1",
                        backgroundColor: "white",
                      }}
                      className="text_align_center"
                    >
                      {"-"}
                    </td>
                    <td
                      style={{
                        color: "black",
                        position: "sticky",
                        left: "110px",
                        zIndex: "1",
                        backgroundColor: "white",
                      }}
                      className="text_align_center"
                    >
                      {"-"}
                    </td>
                    <td
                      style={{ color: "black" }}
                      className="text_align_center"
                    >
                      {"-"}
                    </td>
                    <td
                      style={{ color: "black" }}
                      className="text_align_center"
                    >
                      {"-"}
                    </td>
                    <td
                      style={{ color: "black" }}
                      className="text_align_center"
                    >
                      {reportData.reduce(
                        (a, c) => a + parseInt(c.basic_pay),
                        0
                      )}
                    </td>
                    <td
                      style={{ color: "black" }}
                      className="text_align_center"
                    >
                      {"-"}
                    </td>
                    <td
                      style={{ color: "black" }}
                      className="text_align_center"
                    >
                      {reportData.reduce(
                        (a, c) => a + parseInt(c.increment),
                        0
                      )}
                    </td>
                    <td
                      style={{ color: "black" }}
                      className="text_align_center"
                    >
                      {reportData.reduce((a, c) => a + parseInt(c.eobi), 0)}
                    </td>
                    <td
                      style={{ color: "black" }}
                      className="text_align_center"
                    >
                      {reportData.reduce(
                        (a, c) => a + parseInt(c.eobi_payments),
                        0
                      )}
                    </td>
                    <td
                      style={{ color: "black" }}
                      className="text_align_center"
                    >
                      {reportData.reduce((a, c) => a + parseInt(c.ugs), 0)}
                    </td>
                    <td
                      style={{ color: "black" }}
                      className="text_align_center"
                    >
                      {reportData.reduce(
                        (a, c) => a + parseInt(c.additional_allowance),
                        0
                      )}
                    </td>
                    <td
                      style={{ color: "black" }}
                      className="text_align_center"
                    >
                      {reportData.reduce(
                        (a, c) => a + parseInt(c.extra_period),
                        0
                      )}
                    </td>
                    <td
                      style={{ color: "black" }}
                      className="text_align_center"
                    >
                      {reportData.reduce(
                        (a, c) => a + parseInt(c.extra_coaching),
                        0
                      )}
                    </td>
                    <td
                      style={{ color: "black" }}
                      className="text_align_center"
                    >
                      {reportData.reduce(
                        (a, c) => a + parseInt(c.second_shift),
                        0
                      )}
                    </td>
                    <td
                      style={{ color: "black" }}
                      className="text_align_center"
                    >
                      {reportData.reduce((a, c) => a + parseInt(c.hod), 0)}
                    </td>
                    <td
                      style={{ color: "black" }}
                      className="text_align_center"
                    >
                      {reportData.reduce((a, c) => a + parseInt(c.college), 0)}
                    </td>
                    <td
                      style={{ color: "black" }}
                      className="text_align_center"
                    >
                      {reportData.reduce((a, c) => a + parseInt(c.hostel), 0)}
                    </td>
                    <td
                      style={{ color: "black" }}
                      className="text_align_center"
                    >
                      {reportData.reduce((a, c) => a + parseInt(c.hifz), 0)}
                    </td>
                    <td
                      style={{ color: "black" }}
                      className="text_align_center"
                    >
                      {reportData.reduce(
                        (a, c) => a + parseInt(c.other_allowance),
                        0
                      )}
                    </td>
                    <td
                      style={{ color: "black" }}
                      className="text_align_center"
                    >
                      {reportData.reduce((a, c) => a + parseInt(c.convance), 0)}
                    </td>
                    <td
                      style={{ color: "black" }}
                      className="text_align_center"
                    >
                      {reportData.reduce((a, c) => a + parseInt(c.science), 0)}
                    </td>
                    <td
                      style={{ color: "black" }}
                      className="text_align_center"
                    >
                      {reportData.reduce(
                        (a, c) => a + parseInt(c.gpf_return),
                        0
                      )}
                    </td>

                    <td
                      style={{ color: "black" }}
                      className="text_align_center"
                    >
                      {reportData.reduce((a, c) => a + parseInt(c.gpf_cont), 0)}
                    </td>
                    <td
                      style={{
                        color: "black",
                        borderLeft: "1px solid black",
                        borderRight: "1px solid black",
                        backgroundColor: "beige",
                      }}
                      className="text_align_center"
                    >
                      {reportData.reduce(
                        (a, c) => a + parseInt(c.gross_salary),
                        0
                      )}
                    </td>
                    <td
                      style={{ color: "black" }}
                      className="text_align_center"
                    >
                      {reportData.reduce((a, c) => a + parseInt(c.gp_fund), 0)}
                    </td>
                    <td
                      style={{ color: "black" }}
                      className="text_align_center"
                    >
                      {reportData.reduce(
                        (a, c) => a + parseInt(c.loan_refund),
                        0
                      )}
                    </td>
                    <td
                      style={{ color: "black" }}
                      className="text_align_center"
                    >
                      {reportData.reduce(
                        (a, c) => a + parseInt(c.insurance),
                        0
                      )}
                    </td>
                    <td
                      style={{ color: "black" }}
                      className="text_align_center"
                    >
                      {reportData.reduce(
                        (a, c) => a + parseInt(c.income_tax),
                        0
                      )}
                    </td>
                    <td
                      style={{ color: "black" }}
                      className="text_align_center"
                    >
                      {reportData.reduce(
                        (a, c) => a + parseInt(c.van_charge),
                        0
                      )}
                    </td>
                    <td
                      style={{ color: "black" }}
                      className="text_align_center"
                    >
                      {reportData.reduce(
                        (a, c) => a + parseInt(c.child_fee_deduction),
                        0
                      )}
                    </td>
                    <td
                      style={{ color: "black" }}
                      className="text_align_center"
                    >
                      {reportData.reduce(
                        (a, c) => a + parseInt(c.welfare_fund),
                        0
                      )}
                    </td>
                    <td
                      style={{ color: "black" }}
                      className="text_align_center"
                    >
                      {reportData.reduce(
                        (a, c) => a + parseInt(c.other_deduction),
                        0
                      )}
                    </td>
                    <td
                      style={{ color: "black" }}
                      className="text_align_center"
                    >
                      {reportData.reduce((a, c) => a + parseInt(c.net_pay), 0)}
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        )}

        <div
          ref={componentRef}
          id="printSection"
          style={{ visibility: "hidden" }}
        >
          <div className="row p-3">
            <img style={{ height: 100, width: 110 }} src={icon} />

            <div className="col-md-10">
              <h1>{unitName}</h1>
              <p className="paybodyFont nextmoreFont">
                <b>
                  PAY SHEET FOR THE MONTH OF {formatDate(month)} (
                  {fullCampus?.name}){""}
                </b>
              </p>
            </div>
          </div>
          <table style={{ width: "100%" }}>
            <thead>
              <tr>
                <td
                  className="text-center hereLineheighter"
                  style={{ border: "1px solid black" }}
                >
                  <b>Sr.No</b>
                </td>
                <td
                  className="text-center hereLineheighter"
                  style={{ border: "1px solid black" }}
                >
                  <b>Code</b>
                </td>
                <td
                  className="text-center hereLineheighter"
                  style={{ border: "1px solid black", width: "250px" }}
                >
                  <p>
                    <b>Name of the Employee</b>
                  </p>{" "}
                  <br />{" "}
                  <p>
                    <b>Account No</b>
                  </p>
                </td>
                <td
                  className="text-center hereLineheighter rotateIT"
                  style={{ border: "1px solid black" }}
                >
                  <p className="">
                    <b>Salary Days</b>
                  </p>
                </td>
                <td
                  className="text-center hereLineheighter rotateIT"
                  style={{ border: "1px solid black" }}
                >
                  <p>
                    <b>Pay Scale</b>
                  </p>
                </td>
                <td
                  className="text-center hereLineheighter"
                  style={{ border: "1px solid black" }}
                >
                  <p>
                    <b>Basic Pay</b>{" "}
                  </p>
                  <p>
                    <b>Increment Total</b>
                  </p>{" "}
                </td>
                <td
                  className="text-center hereLineheighter"
                  style={{ border: "1px solid black" }}
                >
                  <p>
                    <b>Conv Allow</b>
                  </p>{" "}
                  <br />{" "}
                  <p>
                    <b>Science Allow</b>
                  </p>
                </td>
                <td
                  className="text-center hereLineheighter"
                  style={{ border: "1px solid black" }}
                >
                  <p>
                    <b>UGS Allow</b>{" "}
                  </p>
                  <p>
                    <b>Extra CA</b>
                  </p>{" "}
                </td>
                <td
                  className="text-center hereLineheighter"
                  style={{ border: "1px solid black" }}
                >
                  <p>
                    <b>Additional Allow</b>
                  </p>
                  <p>
                    <b>Extra Period Allow</b>
                  </p>
                </td>
                <td
                  className="text-center hereLineheighter"
                  style={{ border: "1px solid black" }}
                >
                  <p>
                    <b>2nd Shift Allow</b>
                  </p>{" "}
                  <p>
                    <b>HOD Allow</b>
                  </p>
                </td>
                <td
                  className="text-center hereLineheighter"
                  style={{ border: "1px solid black" }}
                >
                  <p>
                    <b>College Allow</b>
                  </p>{" "}
                  <p>
                    <b>Hostel Allow</b>
                  </p>{" "}
                </td>
                <td
                  className="text-center hereLineheighter"
                  style={{ border: "1px solid black" }}
                >
                  <p>
                    <b>Other Allow</b>
                  </p>{" "}
                  <p>
                    <b>Hifz Allow</b>
                  </p>{" "}
                </td>
                <td
                  className="text-center hereLineheighter"
                  style={{ border: "1px solid black" }}
                >
                  <p>
                    <b>GPF Return</b>
                  </p>{" "}
                  <p>
                    <b>GPF Cont.</b>
                  </p>{" "}
                </td>

                <td
                  className="text-center hereLineheighter"
                  style={{ border: "1px solid black" }}
                >
                  <p>
                    <b>Gross Salary</b>
                  </p>
                </td>
                <td
                  className="text-center hereLineheighter"
                  style={{ border: "1px solid black" }}
                  colSpan={5}
                >
                  <b>Deduction</b>
                  <td
                    className="text-center hereLineheighter"
                    style={{
                      borderRight: "1px solid black",
                      borderTop: "1px solid black",
                      width: "50px",
                    }}
                  >
                    <p>
                      <b>GPF</b>
                    </p>{" "}
                    <p>
                      <b>Insu Dedu</b>
                    </p>{" "}
                  </td>
                  <td
                    className="text-center hereLineheighter"
                    style={{
                      borderRight: "1px solid black",
                      borderTop: "1px solid black",
                      width: "50px",
                    }}
                  >
                    <p>
                      <b>I-Tax</b>
                    </p>{" "}
                    <p>
                      <b>Staff Van Dedu</b>
                    </p>{" "}
                  </td>
                  <td
                    className="text-center hereLineheighter"
                    style={{
                      borderRight: "1px solid black",
                      borderTop: "1px solid black",
                      width: "50px",
                    }}
                  >
                    <p>
                      <b>EOBI</b>
                    </p>{" "}
                    <p>
                      <b>Child Fee Dedu</b>
                    </p>
                  </td>
                  <td
                    className="text-center hereLineheighter"
                    style={{
                      borderTop: "1px solid black",
                      borderRight: "1px solid black",
                      width: "50px",
                    }}
                  >
                    <p>
                      <b>EOBI Pay</b>
                    </p>{" "}
                    <p>
                      <b>WF</b>
                    </p>
                  </td>
                  <td
                    className="text-center hereLineheighter"
                    style={{ borderTop: "1px solid black", width: "50px" }}
                  >
                    <p>
                      <b>Other Deduc</b>
                    </p>{" "}
                    <p>
                      <b>Loan Refun</b>
                    </p>
                  </td>
                </td>
                <td
                  className="text-center hereLineheighter"
                  style={{ border: "1px solid black" }}
                >
                  <b>Net Pay</b>
                </td>
                <td
                  className="text-center hereLineheighter"
                  style={{ border: "1px solid black" }}
                >
                  <b>Voucher</b>
                </td>
                {/* <td className="text-center hereLineheighter" style={{border:"1px solid black"}}><b>Account No</b></td> */}
              </tr>
            </thead>

            <tbody>
              {reportData.length > 0 &&
                reportData.map((subitem, index) => (
                  <tr key={index} id="tbl">
                    <td
                      style={{ color: "black", border: "1px solid black" }}
                      className="text_align_center"
                    >
                      {index + 1}
                    </td>
                    <td
                      style={{
                        color: "black",
                        border: "1px solid black",
                        textAlign: "center",
                      }}
                      className=""
                    >
                      {subitem.employee.emp_code}
                    </td>
                    <td
                      style={{ color: "black", border: "1px solid black" }}
                      className="text_align_center"
                    >
                      {subitem.employee.full_name} <br />{" "}
                      {subitem.employee.account_no}
                    </td>
                    <td
                      style={{ color: "black", border: "1px solid black" }}
                      className="text_align_center"
                    >
                      {subitem.days}
                    </td>
                    <td
                      style={{ color: "black", border: "1px solid black" }}
                      className="text_align_center"
                    >
                      {subitem.employee.pay_scale.payscale}
                    </td>
                    <td
                      style={{ color: "black", border: "1px solid black" }}
                      className="text_align_center"
                    >
                      {subitem.basic_pay} <br /> {subitem.increment}
                    </td>
                    <td
                      style={{ color: "black", border: "1px solid black" }}
                      className="text_align_center"
                    >
                      {subitem.convance} <br /> {subitem.science}
                    </td>
                    <td
                      style={{ color: "black", border: "1px solid black" }}
                      className="text_align_center"
                    >
                      {subitem.ugs} <br /> {subitem.extra_coaching}
                    </td>
                    <td
                      style={{ color: "black", border: "1px solid black" }}
                      className="text_align_center"
                    >
                      {subitem.additional_allowance} <br />{" "}
                      {subitem.extra_period}
                    </td>
                    <td
                      style={{ color: "black", border: "1px solid black" }}
                      className="text_align_center"
                    >
                      {subitem.second_shift} <br /> {subitem.hod}
                    </td>
                    <td
                      style={{ color: "black", border: "1px solid black" }}
                      className="text_align_center"
                    >
                      {subitem.college} <br /> {subitem.hostel}
                    </td>
                    <td
                      style={{ color: "black", border: "1px solid black" }}
                      className="text_align_center"
                    >
                      {subitem.other_allowance} <br /> {subitem.hifz}
                    </td>
                    <td
                      style={{ color: "black", border: "1px solid black" }}
                      className="text_align_center"
                    >
                      {subitem.gpf_return} <br /> {subitem.gpf_cont}
                    </td>
                    <td
                      style={{ color: "black", border: "1px solid black" }}
                      className="text_align_center"
                    >
                      {parseInt(subitem.gross_salary)}
                    </td>
                    <td
                      style={{
                        color: "black",
                        border: "1px solid black",
                        width: "50px",
                      }}
                      className="text_align_center"
                    >
                      {subitem.gp_fund} <br /> {subitem.insurance}
                    </td>
                    <td
                      style={{
                        color: "black",
                        border: "1px solid black",
                        width: "50px",
                      }}
                      className="text_align_center"
                    >
                      {subitem.income_tax} <br /> {subitem.van_charge}
                    </td>
                    <td
                      style={{
                        color: "black",
                        border: "1px solid black",
                        width: "50px",
                      }}
                      className="text_align_center"
                    >
                      {subitem.eobi} <br /> {subitem.child_fee_deduction}
                    </td>
                    <td
                      style={{
                        color: "black",
                        border: "1px solid black",
                        width: "50px",
                      }}
                      className="text_align_center"
                    >
                      {subitem.eobi_payments} <br /> {subitem.welfare_fund}
                    </td>
                    <td
                      style={{
                        color: "black",
                        border: "1px solid black",
                        width: "50px",
                      }}
                      className="text_align_center"
                    >
                      {subitem.other_deduction} <br /> {subitem.loan_refund}
                    </td>
                    <td
                      style={{ color: "black", border: "1px solid black" }}
                      className="text_align_center"
                    >
                      {parseInt(subitem.net_pay)}
                    </td>
                    <td
                      style={{ color: "black", border: "1px solid black" }}
                      className="text_align_center"
                    >
                      {subitem.voucher?.voucher_no}
                    </td>
                    {/* <td style={{ color: "black",border:"1px solid black" }} className="text_align_center">
                    {subitem.employee.account_no}
                  </td> */}
                  </tr>
                ))}
              <tr id="tbl">
                <td
                  style={{ color: "black", border: "1px solid black" }}
                  className="text_align_center"
                >
                  {"-"}
                </td>
                <td
                  style={{
                    color: "black",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                  className=""
                >
                  {"-"}
                </td>
                <td
                  style={{ color: "black", border: "1px solid black" }}
                  className="text_align_center"
                >
                  {"-"}
                </td>
                <td
                  style={{ color: "black", border: "1px solid black" }}
                  className="text_align_center"
                >
                  {"-"}
                </td>
                <td
                  style={{ color: "black", border: "1px solid black" }}
                  className="text_align_center"
                >
                  {"-"}
                </td>
                <td
                  style={{ color: "black", border: "1px solid black" }}
                  className="text_align_center"
                >
                  {reportData.reduce((a, c) => a + parseInt(c.basic_pay), 0)}
                  <br />{" "}
                  {reportData.reduce((a, c) => a + parseInt(c.increment), 0)}
                </td>
                <td
                  style={{ color: "black", border: "1px solid black" }}
                  className="text_align_center"
                >
                  {reportData.reduce((a, c) => a + parseInt(c.convance), 0)}{" "}
                  <br />{" "}
                  {reportData.reduce((a, c) => a + parseInt(c.science), 0)}
                </td>
                <td
                  style={{ color: "black", border: "1px solid black" }}
                  className="text_align_center"
                >
                  {reportData.reduce((a, c) => a + parseInt(c.ugs), 0)} <br />{" "}
                  {reportData.reduce(
                    (a, c) => a + parseInt(c.extra_coaching),
                    0
                  )}
                </td>
                <td
                  style={{ color: "black", border: "1px solid black" }}
                  className="text_align_center"
                >
                  {reportData.reduce(
                    (a, c) => a + parseInt(c.additional_allowance),
                    0
                  )}{" "}
                  <br />{" "}
                  {reportData.reduce((a, c) => a + parseInt(c.extra_period), 0)}
                </td>
                <td
                  style={{ color: "black", border: "1px solid black" }}
                  className="text_align_center"
                >
                  {reportData.reduce((a, c) => a + parseInt(c.second_shift), 0)}{" "}
                  <br /> {reportData.reduce((a, c) => a + parseInt(c.hod), 0)}
                </td>
                <td
                  style={{ color: "black", border: "1px solid black" }}
                  className="text_align_center"
                >
                  {reportData.reduce((a, c) => a + parseInt(c.college), 0)}{" "}
                  <br />{" "}
                  {reportData.reduce((a, c) => a + parseInt(c.hostel), 0)}
                </td>
                <td
                  style={{ color: "black", border: "1px solid black" }}
                  className="text_align_center"
                >
                  {reportData.reduce(
                    (a, c) => a + parseInt(c.other_allowance),
                    0
                  )}{" "}
                  <br /> {reportData.reduce((a, c) => a + parseInt(c.hifz), 0)}
                </td>
                <td
                  style={{ color: "black", border: "1px solid black" }}
                  className="text_align_center"
                >
                  {reportData.reduce((a, c) => a + parseInt(c.gpf_return), 0)}{" "}
                  <br />{" "}
                  {reportData.reduce((a, c) => a + parseInt(c.gpf_cont), 0)}
                </td>
                <td
                  style={{ color: "black", border: "1px solid black" }}
                  className="text_align_center"
                >
                  {reportData.reduce((a, c) => a + parseInt(c.gross_salary), 0)}
                </td>
                <td
                  style={{
                    color: "black",
                    border: "1px solid black",
                    width: "50px",
                  }}
                  className="text_align_center"
                >
                  {reportData.reduce((a, c) => a + parseInt(c.gp_fund), 0)}{" "}
                  <br />{" "}
                  {reportData.reduce((a, c) => a + parseInt(c.insurance), 0)}
                </td>
                <td
                  style={{
                    color: "black",
                    border: "1px solid black",
                    width: "50px",
                  }}
                  className="text_align_center"
                >
                  {reportData.reduce((a, c) => a + parseInt(c.income_tax), 0)}{" "}
                  <br />{" "}
                  {reportData.reduce((a, c) => a + parseInt(c.van_charge), 0)}
                </td>
                <td
                  style={{
                    color: "black",
                    border: "1px solid black",
                    width: "50px",
                  }}
                  className="text_align_center"
                >
                  {reportData.reduce((a, c) => a + parseInt(c.eobi), 0)} <br />{" "}
                  {reportData.reduce(
                    (a, c) => a + parseInt(c.child_fee_deduction),
                    0
                  )}
                </td>
                <td
                  style={{
                    color: "black",
                    border: "1px solid black",
                    width: "50px",
                  }}
                  className="text_align_center"
                >
                  {reportData.reduce(
                    (a, c) => a + parseInt(c.eobi_payments),
                    0
                  )}{" "}
                  <br />{" "}
                  {reportData.reduce((a, c) => a + parseInt(c.welfare_fund), 0)}
                </td>
                <td
                  style={{
                    color: "black",
                    border: "1px solid black",
                    width: "50px",
                  }}
                  className="text_align_center"
                >
                  {reportData.reduce(
                    (a, c) => a + parseInt(c.other_deduction),
                    0
                  )}{" "}
                  <br />{" "}
                  {reportData.reduce((a, c) => a + parseInt(c.loan_refund), 0)}
                </td>
                <td
                  style={{ color: "black", border: "1px solid black" }}
                  className="text_align_center"
                >
                  {reportData.reduce((a, c) => a + parseInt(c.net_pay), 0)}
                </td>
                <td
                  style={{ color: "black", border: "1px solid black" }}
                  className="text_align_center"
                >
                  {"-"}
                </td>
                {/* <td style={{ color: "black",border:"1px solid black" }} className="text_align_center">
                    {subitem.employee.account_no}
                  </td> */}
              </tr>
            </tbody>
          </table>
          <div className="endTotalGlossNet">
            <div>
              <p>
                <b style={{ fontSize: "18px" }}>
                  Gross salary: {Intl.NumberFormat("en-Us").format(glossTotal)}
                </b>
              </p>
            </div>
            <div>
              <p>
                <b style={{ fontSize: "18px" }}>
                  Net Pay: {Intl.NumberFormat("en-Us").format(netTotal)}
                </b>
              </p>
            </div>
          </div>
        </div>

        <Table ref={tableRef} style={{ display: "none" }}>
          <thead>
            <tr id="tbl" style={{ position: "relative" }}>
              <th className="text-center"></th>
              <th className="text-center"></th>
              <th className="text-center"></th>
              <th className="text-center"></th>
              <th className="text-center"></th>
              <th className="text-center"></th>
              <th className="text-center"></th>
              <th className="text-center"></th>
              <th className="text-center"></th>
              <th className="text-center"></th>
              <th className="text-center"></th>
              <th
                className="text-center text-primary"
                style={{
                  position: "absolute",
                  width: "550px",
                  right: "45%",
                  bottom: "-18%",
                  borderBottom: "2px solid purple",
                }}
              >
                ALLOWANCES
              </th>
              <th className="text-center"></th>
              <th className="text-center"></th>
              <th className="text-center"></th>
              <th className="text-center"></th>
              <th className="text-center"></th>
              <th className="text-center"></th>
              <th className="text-center"></th>
              <th className="text-center"></th>
              <th className="text-center"></th>
              <th className="text-center"></th>
              <th className="text-center"></th>
              <th className="text-center"></th>
              <th className="text-center"></th>
              <th className="text-center"></th>
              <th className="text-center"></th>
              <th className="text-center"></th>
              <th
                className="text-center text-primary"
                style={{
                  position: "absolute",
                  width: "550px",
                  right: "5%",
                  bottom: "-18%",
                  borderBottom: "2px solid purple",
                }}
              >
                DEDUCTIONS
              </th>
              <th className="text-center"></th>
              <th className="text-center"></th>
              <th className="text-center"></th>
            </tr>
            <tr
              id="tbl"
              style={{
                position: "sticky",
                top: "0",
                zIndex: "2",
                backgroundColor: "gainsboro",
              }}
            >
              <th
                className="text-center"
                style={{
                  position: "sticky",
                  left: "0",
                  zIndex: "2",
                  backgroundColor: "gainsboro",
                }}
              >
                Sr.No
              </th>
              <th
                className="text-center"
                style={{
                  position: "sticky",
                  left: "55px",
                  zIndex: "2",
                  backgroundColor: "gainsboro",
                }}
              >
                Code
              </th>
              <th
                className="text-center"
                style={{
                  position: "sticky",
                  left: "110px",
                  zIndex: "2",
                  backgroundColor: "gainsboro",
                }}
              >
                Name
              </th>
              <th className="text-center">Salary Days</th>
              <th className="text-center">Pay Scale</th>
              <th className="text-center">Basic Pay</th>
              <th className="text-center">Voucher</th>
              <th className="text-center">Increment Total</th>
              <th className="text-center">Eobi</th>
              <th className="text-center">Eobi Payment</th>
              <th className="text-center">UGS Allowance</th>
              <th className="text-center">Additional Allowance</th>
              <th className="text-center">Extra Period Allowance</th>
              <th className="text-center">Extra Coaching</th>
              <th className="text-center">2nd Shift Allowance</th>
              <th className="text-center">HOD Allowance</th>
              <th className="text-center">College Allowance</th>
              <th className="text-center">Hostel Allowance</th>
              <th className="text-center">Hifz Allowance</th>
              <th className="text-center">Other Allowance</th>
              <th className="text-center">Conv Allow</th>
              <th className="text-center">Science</th>
              <th className="text-center">GPF Return</th>
              <th
                className="text-center"
                style={{
                  borderLeft: "1px solid black",
                  borderRight: "1px solid black",
                }}
              >
                Gross Salary
              </th>
              <th className="text-center">GP Fund</th>
              <th className="text-center">Loan Refund</th>
              <th className="text-center">Insu Dedu</th>
              <th className="text-center">Income Tax</th>
              <th className="text-center">Staff Van Dedu</th>
              <th className="text-center">Child Fee Dedu</th>
              <th className="text-center">Welfare Fund</th>
              <th className="text-center">Other Dedu</th>
              <th className="text-center">Net Salary</th>
            </tr>
          </thead>

          <tbody>
            {reportData.length > 0 &&
              reportData.map((subitem, index) => (
                <tr key={index} id="tbl">
                  <td
                    style={{
                      color: "black",
                      position: "sticky",
                      left: "0",
                      zIndex: "1",
                      backgroundColor: "white",
                    }}
                    className="text_align_center"
                  >
                    {index + 1}
                  </td>
                  <td
                    style={{
                      color: "black",
                      position: "sticky",
                      left: "55px",
                      zIndex: "1",
                      backgroundColor: "white",
                    }}
                    className="text_align_center"
                  >
                    {subitem.employee.emp_code}
                  </td>
                  <td
                    style={{
                      color: "black",
                      position: "sticky",
                      left: "110px",
                      zIndex: "1",
                      backgroundColor: "white",
                    }}
                    className="text_align_center"
                  >
                    {subitem.employee.full_name}
                  </td>
                  <td style={{ color: "black" }} className="text_align_center">
                    {subitem.days}
                  </td>
                  <td style={{ color: "black" }} className="text_align_center">
                    {subitem.employee.pay_scale.payscale}
                  </td>
                  <td style={{ color: "black" }} className="text_align_center">
                    {subitem.basic_pay}
                  </td>
                  <td style={{ color: "black" }} className="text_align_center">
                    {subitem.voucher?.voucher_no}
                  </td>
                  <td style={{ color: "black" }} className="text_align_center">
                    {subitem.increment}
                  </td>
                  <td style={{ color: "black" }} className="text_align_center">
                    {subitem.eobi}
                  </td>
                  <td style={{ color: "black" }} className="text_align_center">
                    {subitem.eobi_payments}
                  </td>
                  <td style={{ color: "black" }} className="text_align_center">
                    {subitem.ugs}
                  </td>
                  <td style={{ color: "black" }} className="text_align_center">
                    {subitem.additional_allowance}
                  </td>
                  <td style={{ color: "black" }} className="text_align_center">
                    {subitem.extra_period}
                  </td>
                  <td style={{ color: "black" }} className="text_align_center">
                    {subitem.extra_coaching}
                  </td>
                  <td style={{ color: "black" }} className="text_align_center">
                    {subitem.second_shift}
                  </td>
                  <td style={{ color: "black" }} className="text_align_center">
                    {subitem.hod}
                  </td>
                  <td style={{ color: "black" }} className="text_align_center">
                    {subitem.college}
                  </td>
                  <td style={{ color: "black" }} className="text_align_center">
                    {subitem.hostel}
                  </td>
                  <td style={{ color: "black" }} className="text_align_center">
                    {subitem.hifz}
                  </td>
                  <td style={{ color: "black" }} className="text_align_center">
                    {subitem.other_allowance}
                  </td>
                  <td style={{ color: "black" }} className="text_align_center">
                    {subitem.convance}
                  </td>
                  <td style={{ color: "black" }} className="text_align_center">
                    {subitem.science}
                  </td>
                  <td style={{ color: "black" }} className="text_align_center">
                    {subitem.gpf_return}
                  </td>
                  <td
                    style={{
                      color: "black",
                      borderLeft: "1px solid black",
                      borderRight: "1px solid black",
                      backgroundColor: "beige",
                    }}
                    className="text_align_center"
                  >
                    {Number(subitem.gross_salary)}
                  </td>
                  <td style={{ color: "black" }} className="text_align_center">
                    {subitem.gp_fund}
                  </td>
                  <td style={{ color: "black" }} className="text_align_center">
                    {subitem.loan_refund}
                  </td>
                  <td style={{ color: "black" }} className="text_align_center">
                    {subitem.insurance}
                  </td>
                  <td style={{ color: "black" }} className="text_align_center">
                    {subitem.income_tax}
                  </td>
                  <td style={{ color: "black" }} className="text_align_center">
                    {subitem.van_charge}
                  </td>
                  <td style={{ color: "black" }} className="text_align_center">
                    {subitem.child_fee_deduction}
                  </td>
                  <td style={{ color: "black" }} className="text_align_center">
                    {subitem.welfare_fund}
                  </td>
                  <td style={{ color: "black" }} className="text_align_center">
                    {subitem.other_deduction}
                  </td>
                  <td style={{ color: "black" }} className="text_align_center">
                    {Number(subitem.net_pay)}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        {/* {loginAccount == "Campus" ? <PasswordModal /> : ""} */}
      </Paper>
    </>
  );
};

export default MonthlyPaySheet;
