import React, { useEffect, useState, useRef } from "react";
import { Divider } from "antd";
import { LinearProgress, Paper } from "@material-ui/core";
import { Table, Modal } from "react-bootstrap";
import { baseUrl } from "../../../services/config";
import { useAlert } from "react-alert";
import ReactToPrint from "react-to-print";
import "./styles.css";
import { useSelector } from "react-redux";
import { PremissionChecker } from "../../../services/PremissionChecker";
import toast from "react-hot-toast";

const SchoolLeavingCertificate = () => {
  const user = useSelector((state) => state.user.user);
  const currentDates = new Date().toISOString().slice(0, 10);
  const [stdID, setstdID] = useState([]);
  const [currentsession, setcurrentsession] = useState("");
  const [admission_id, setAdmission_id] = useState("");
  const [scode, setsCode] = useState("");
  const [editmodal, seteditmodal] = useState(false);
  const [saveButton, setSaveButton] = useState(
    !PremissionChecker(user, "student.generate_certificate")
  );
  const [date, setDate] = useState(currentDates);
  const [issueDate, setIssueDate] = useState(currentDates);
  const [title, setTitle] = useState("");
  const [studentName, setStudentName] = useState("");
  const [fatherName, setFatherName] = useState("");
  const [admissionDate, setAdmissionDate] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [leavingDate, setLeavingDate] = useState(currentDates);
  const [dateOfBithInWords, setDateOfBithInWords] = useState("");
  const [adobword, setadobword] = useState("");
  const [yearID, setYearID] = useState("");
  const [currentClassName, setCurrentClassName] = useState("");
  const [sectionName, setSectionName] = useState("");
  const [campusName, setCampusName] = useState("");
  const [previousClass, setPreviousClass] = useState("---");
  const [obtainMarks, setObtainMarks] = useState(0);
  const [totalMarks, setTotalMarks] = useState(0);
  const [classPosition, setClassPosition] = useState("---");
  const [attandance, setAttandance] = useState(0);
  const [totalAttandance, setTotalAttandance] = useState(0);
  const [curricularDetails, setCurricularDetails] = useState("---");
  const [tehsil, setTehsil] = useState("");
  const [district, setDistrict] = useState("");
  const [printdisable, setprintdisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reportData, setreportData] = useState([]);
  var [dateof, setdateof] = useState([]);
  const [sessioning, setallsession] = useState([]);
  const [certificate_Id, setCertificate_Id] = useState([]);
  const [totalFee, setTotalFee] = useState([]);
  const [campuschallans, setcampuschallans] = useState([]);
  const [duechallans, setduechallans] = useState([]);
  let authtoken = localStorage.getItem("userToken");
  const [showDistrict, setShowDistrict] = useState(false);
  const [icon, setIcon] = useState([]);
  const [unitName, setUnitName] = useState("");

  //for print
  const [printloading, setprintloading] = useState(false);

  var inputDate = "";
  const [text, setText] = React.useState("old boring text");
  const componentRef = useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);
  const alert = useAlert();

  // Function to convert year to words
  // Function to convert a date string to a custom format
  function formatDate(inputDate) {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const parts = inputDate.split("-");
    const day = parseInt(parts[2]);
    const month = parseInt(parts[1]) - 1; // Months are 0-indexed in JavaScript's Date object
    const year = parseInt(parts[0]);

    const dayWords = [
      "Zeroth",
      "First",
      "Second",
      "Third",
      "Fourth",
      "Fifth",
      "Sixth",
      "Seventh",
      "Eighth",
      "Ninth",
      "Tenth",
      "Eleventh",
      "Twelveth",
      "Thirteenth",
      "Fourteenth",
      "Fifteenth",
      "Sixteenth",
      "Seventeenth",
      "Eighteenth",
      "Nineteenth",
      "Twentieth",
      "Twenty-first",
      "Twenty-second",
      "Twenty-third",
      "Twenty-fourth",
      "Twenty-fifth",
      "Twenty-sixth",
      "Twenty-seventh",
      "Twenty-eighth",
      "Twenty-ninth",
      "Thirtieth",
      "Thirty-first",
    ];

    const formattedDate = `${dayWords[day]} ${months[month]} ${yearToWords(
      year
    )}`;
    return formattedDate;
  }

  // Function to convert year to words
  function yearToWords(year) {
    const numWords = [
      "Zero",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Sight",
      "Nine",
      "Ten",
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
    ];

    const tensWords = [
      "",
      "One ",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];

    if (year >= 1000 && year <= 9999) {
      const thousands = Math.floor(year / 1000);
      const hundreds = Math.floor((year % 1000) / 100);
      const tens = Math.floor((year % 100) / 10);
      const ones = year % 10;

      const thousandsWord = numWords[thousands];
      const hundredsWord = numWords[hundreds];
      const tensWord = tensWords[tens];
      const onesWord = numWords[ones];
      if (hundreds === 0) {
        if (tens === 0) {
          return `${thousandsWord} Thousand ${onesWord}`;
        } else if (tens === 1) {
          return `${thousandsWord} Thousand ${numWords[tens * 10 + ones]}`;
        } else {
          return `${thousandsWord} Thousand ${tensWord} ${onesWord}`;
        }
      } else if (tens === 1) {
        return `${thousandsWord} Thousand ${hundredsWord} Hundred ${
          numWords[tens * 10 + ones]
        }`;
      } else {
        return `${thousandsWord} Thousand ${hundredsWord} Hundred ${tensWord} ${onesWord}`;
      }

      return "Invalid year";
    }
  }

  const getMonthName = (date) => {
    if (date == "") {
      return "_";
    }
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const d = new Date(date);

    return `${monthNames[d.getMonth()]}-${d.getFullYear()}`;
  };

  useEffect(() => {
    getallsessions();
    max_id();
    getSettingData();
  }, []);

  const getallsessions = async () => {
    await fetch(`${baseUrl}/session`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setallsession(data.payload);
        data.payload.map((session, index) => {
          if (session.active_financial_year == "1") {
            setcurrentsession(session.id);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const max_id = async () => {
    await fetch(`${baseUrl}/certificate-max-id`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setCertificate_Id(parseInt(data.payload) + 1);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setUnitName(data.payload.settings.unit_name);
        setIcon(data.payload.settings.logo_file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleShow = () => {
    setreportData([]);
    setStudentName("");
    setFatherName("");
    setAdmissionDate("");
    setDateOfBirth("");
    setYearID("");
    setCurrentClassName("");
    setSectionName("");
    setCampusName("");
    setCurrentClassName("");
    setPreviousClass("---");
    setObtainMarks(0);
    setTotalMarks(0);
    setClassPosition("---");
    setAttandance(0);
    setTotalAttandance(0);
    setCurricularDetails("---");
    setTehsil("");
    setDistrict("");
    setDateOfBithInWords("");
    setcampuschallans([]);
    setduechallans([]);
    var hf = 0;
    setTotalFee(hf);
    if (admission_id == "" && date == "") {
      alert.error("please enter the Student ID and select the date");
      return;
    }
    if (date == "") {
      alert.error("please select the date");
      return;
    }
    if (admission_id == "") {
      alert.error("please enter the Student");
      return;
    }
    setLoading(true);
    setprintloading(true);
    setSaveButton(true);
    const data = {
      admission_id: admission_id.toLocaleUpperCase(),
      certificate_type: "1",
    };
    fetch(`${baseUrl}/view-certificate`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata.success) {
          setLoading(false);
          setSaveButton(false);
          setprintloading(false);

          data.payload.map((campusData) => {
            inputDate = campusData.dob;
            setreportData(data.payload);
            setDateOfBithInWords(formatDate(inputDate));

            setStudentName(campusData.name);
            setFatherName(campusData.father_name);
            setAdmissionDate(campusData.Joining_date);
            setDateOfBirth(campusData.dob);
            setYearID(campusData.year_setting_id);
            setCurrentClassName(campusData.student_class.name);
            setSectionName(campusData.global_section.name);
            setCampusName(campusData.campus.name);

            if (
              campusData.certificate.length > 0 &&
              campusData?.certificate[0]?.certificate_type_id == 1
            ) {
              setPreviousClass(campusData.certificate[0]?.class_passed_fail);
              setObtainMarks(campusData.certificate[0]?.obtain_marks);
              setTotalMarks(campusData.certificate[0]?.total_marks);
              setLeavingDate(campusData.certificate[0]?.leaving_date);
              setClassPosition(campusData.certificate[0]?.class_position);
              setAttandance(campusData.certificate[0]?.attendance);
              setTotalAttandance(campusData.certificate[0]?.total_Attendance);
              setCurricularDetails(campusData.certificate[0]?.activity);
              setIssueDate(campusData.certificate[0]?.issue_date);
              setTehsil(campusData.certificate[0]?.tehsil);
              setDistrict(campusData.certificate[0]?.district);
              setprintloading(true);
            } else if (
              campusData.certificate.length > 0 &&
              campusData?.certificate[1]?.certificate_type_id == 1
            ) {
              setPreviousClass(campusData.certificate[1]?.class_passed_fail);
              setObtainMarks(campusData.certificate[1]?.obtain_marks);
              setTotalMarks(campusData.certificate[1]?.total_marks);
              setLeavingDate(campusData.certificate[1]?.leaving_date);
              setClassPosition(campusData.certificate[1]?.class_position);
              setAttandance(campusData.certificate[1]?.attendance);
              setTotalAttandance(campusData.certificate[1]?.total_Attendance);
              setCurricularDetails(campusData.certificate[1]?.activity);
              setIssueDate(campusData.certificate[1]?.issue_date);
              setTehsil(campusData.certificate[1]?.tehsil);
              setDistrict(campusData.certificate[1]?.district);
              setprintloading(true);
            }
          });
        } else {
          alert.error("Record Not Found");
          setLoading(false);
          return;
        }

        setsCode(data.payload.registration_id);
        seteditmodal(true);

        fetch(
          `${baseUrl}/all-challan-search-for-certificate?id=${admission_id}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${authtoken}`,
            },
          }
        )
          .then((res) => res.json())
          .then((data) => {
            data.map((campusx) => {
              hf =
                hf + parseFloat(campusx.payable == null ? 0 : campusx.payable);
            });
            setTotalFee(hf);
            console.log(data);

            if (
              !PremissionChecker(user, "student.generate_certificate") &&
              data.length > 0
            ) {
              setSaveButton(true);
            } else {
              setSaveButton(false);
            }

            setcampuschallans(data);
            if (data == "") {
              alert.show("No Unpaid Challans Found");
            }
          })
          .catch((err) => {
            console.log(err);
          });
        //end challan data
        fetch(`${baseUrl}/paid-challan-search?id=${admission_id}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${authtoken}`,
          },
        })
          .then((res) => res.json())
          .then((data) => {
            if (data == null) {
              setduechallans([]);
            } else {
              setduechallans(data.fee_challan_details1.fee_month);
            }
          });
      })
      .catch((err) => {
        setLoading(false);
        console.log("err is re", err);
      });
  };

  const addNow = async () => {
    setprintloading(true);
    setSaveButton(true);
    if (totalFee > 0) {
      alert.error("Student has unpaid fee. Please pay to continue.");
      setSaveButton(false);
      setprintloading(false);
      return;
    }
    if (issueDate == "") {
      alert.error("Set Issue Date");
      setSaveButton(false);
      setprintloading(true);
      return;
    }
    if (leavingDate == "") {
      alert.error("Set Leaving Date");
      setSaveButton(false);
      setprintloading(true);
      return;
    }

    const data = {
      admission_id: admission_id,
      passed_class: previousClass,
      obtain_marks: obtainMarks,
      total_Marks: totalMarks,
      class_position: classPosition,
      total_attandance: totalAttandance,
      attandance: attandance,
      migration: curricularDetails,
      issue_date: issueDate,
      leaving_date: leavingDate,
      tehsil: tehsil,
      district: district,
      certificate_type: "1",
    };

    fetch(`${baseUrl}/save-certificate`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata.success) {
          alert.success(data.payload);
        } else if (!data.metadata.success) {
          Object.values(data.metadata.message).map((msg) => {
            alert.error(msg);
          });
        }
      });
    setSaveButton(false);
  };

  //for print
  const handleBeforePrint = React.useCallback(() => {}, []);
  const handleOnBeforeGetContent = React.useCallback(() => {
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setprintloading(true);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setprintloading, setText]);

  const handleAfterPrint = React.useCallback(() => {}, []);

  return (
    <>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        {loading ? <LinearProgress /> : null}

        <div className="row mt-3">
          <div className="col-md-4">
            <b> Date:</b>
            <input
              max="9999-11-30"
              value={currentDates}
              onChange={(e) => setDate(e.target.value)}
              type="date"
              placeholder="Enter Student ID"
              className="form-control"
              style={{ marginTop: "5px" }}
            />
          </div>
          <div className="col-md-4">
            <b> Student Admission ID:</b>
            <input
              onChange={(e) => setAdmission_id(e.target.value)}
              type="text"
              placeholder="Enter Student ID"
              className="form-control"
              style={{ marginTop: "5px" }}
            />
          </div>
          <div className="col d-flex mt-7">
            <div className="mr-4">
              <button onClick={handleShow} className="btn btn-primary">
                Search
              </button>
            </div>
            <div className="mt-3">
              <label className="checkbox checkbox-single">
                <input
                  type="checkbox"
                  onChange={(e) => setShowDistrict(e.target.checked)}
                />
                <span />
                &nbsp;&nbsp;&nbsp;Include Tehsil & District
              </label>
            </div>
          </div>
        </div>
        <Divider />

        {reportData.map((campus, index) => (
          <div key={index} className="mainContainer">
            <div>
              <div className="row mt-3">
                <h3>Admission Form Information</h3>
                <div className="col-md-6" style={{ marginTop: "20px" }}>
                  <b> Student Name:</b>
                  <input
                    type="text"
                    className="form-control"
                    value={campus.name}
                    id="sname"
                    style={{ marginTop: "5px" }}
                    disabled
                  />
                </div>
                <div className="col-md-6" style={{ marginTop: "19px" }}>
                  <b> Father Name:</b>
                  <input
                    type="text"
                    className="form-control"
                    id="fname"
                    style={{ marginTop: "5px" }}
                    value={campus.father_name}
                    disabled
                  />
                </div>
                <div className="col-md-4" style={{ marginTop: "10px" }}>
                  <b> Serial No:</b>
                  <input
                    style={{ marginTop: "5px" }}
                    type="text"
                    className="form-control"
                    value={
                      campus.certificate[0]?.id == null
                        ? certificate_Id
                        : campus.certificate[0]?.id
                    }
                    id="srl"
                    disabled
                  />
                </div>
                {showDistrict ? (
                  <>
                    <div className="col-md-4" style={{ marginTop: "10px" }}>
                      <b> Tehsil:</b>
                      <input
                        style={{ marginTop: "5px" }}
                        type="text"
                        className="form-control"
                        value={tehsil}
                        id="tehsils"
                        onChange={(e) => setTehsil(e.target.value)}
                        placeholder="Enter Tehsil"
                      />
                    </div>
                    <div className="col-md-4" style={{ marginTop: "10px" }}>
                      <b> District:</b>
                      <input
                        style={{ marginTop: "5px" }}
                        type="text"
                        className="form-control"
                        value={district}
                        id="districts"
                        onChange={(e) => setDistrict(e.target.value)}
                        placeholder="Enter District"
                      />
                    </div>
                  </>
                ) : null}
                <div className="col-md-4" style={{ marginTop: "10px" }}>
                  <b>Admission Date:</b>
                  <input
                    type="text"
                    className="form-control"
                    id="adate"
                    style={{ marginTop: "5px" }}
                    defaultValue={campus.Joining_date}
                    disabled
                  />
                </div>
                <div className="col-md-4" style={{ marginTop: "10px" }}>
                  {" "}
                  <b> Date of birth:</b>
                  <input
                    type="text"
                    className="form-control"
                    id="adob"
                    style={{ marginTop: "5px" }}
                    defaultValue={campus.dob}
                    disabled
                  />
                </div>
                <div className="col-md-4" style={{ marginTop: "10px" }}>
                  {" "}
                  <b> Date of birth In Words:</b>
                  <input
                    type="text"
                    style={{ marginTop: "5px" }}
                    className="form-control"
                    id="adobw"
                    defaultValue={dateOfBithInWords}
                  />
                </div>
                <div className="col-md-4" style={{ marginTop: "10px" }}>
                  <b> Session</b>
                  <select
                    className="form-control"
                    defaultValue={currentsession}
                    id="sessioner"
                    style={{ marginTop: "5px" }}
                    disabled
                  >
                    <option value="">Select Session</option>
                    {sessioning.map((classing, index) => (
                      <option key={index} value={classing.id}>
                        {classing.year}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-4" style={{ marginTop: "10px" }}>
                  <b>Study In Class:</b>
                  <input
                    type="text"
                    className="form-control"
                    value={currentClassName}
                    onChange={(e) => setCurrentClassName(e.target.value)}
                    id="cclass"
                    style={{ marginTop: "5px" }}
                    placeholder="Enter Class Name"
                  />
                </div>
                <div className="col-md-4" style={{ marginTop: "10px" }}>
                  <b>Campus:</b>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={campus.campus.name}
                    id="ccampus"
                    style={{ marginTop: "5px" }}
                    disabled
                  />
                </div>
                <div className="col-md-4" style={{ marginTop: "10px" }}>
                  {" "}
                  <b> Section:</b>
                  <input
                    type="text"
                    className="form-control"
                    id="csection"
                    style={{ marginTop: "5px" }}
                    defaultValue={campus.global_section.name}
                    disabled
                  />
                </div>
                <div className="col-md-4" style={{ marginTop: "10px" }}>
                  <b> Issue Date:</b>
                  <input
                    type="date"
                    className="form-control"
                    style={{ marginTop: "5px" }}
                    value={issueDate}
                    id="idate"
                    onChange={(e) => setIssueDate(e.target.value)}
                  />
                </div>
                <div className="col-md-4" style={{ marginTop: "10px" }}>
                  <b> Leaving Date:</b>
                  <input
                    type="date"
                    style={{ marginTop: "5px" }}
                    className="form-control"
                    value={leavingDate}
                    id="ldate"
                    onChange={(e) => setLeavingDate(e.target.value)}
                  />
                </div>
                <div className="col-md-12" style={{ marginTop: "20px" }}>
                  <h3>School Leaving Certificate Information</h3>
                </div>
                <div className="col-md-4" style={{ marginTop: "20px" }}>
                  <b> Passed In Class:</b>
                  <input
                    type="text"
                    className="form-control"
                    id="pclass"
                    style={{ marginTop: "5px" }}
                    onChange={(e) => setPreviousClass(e.target.value)}
                    value={previousClass}
                    placeholder="Enter Passed In Class"
                  />
                </div>
                <div className="col-md-4" style={{ marginTop: "20px" }}>
                  <b>Marks Obtained</b>
                  <input
                    type="number"
                    className="form-control"
                    id="omarks"
                    style={{ marginTop: "5px" }}
                    onChange={(e) => setObtainMarks(e.target.value)}
                    value={obtainMarks}
                    placeholder="Enter Marks Obtained"
                  />
                </div>
                <div className="col-md-4" style={{ marginTop: "20px" }}>
                  <b> Out Of</b>
                  <input
                    type="number"
                    className="form-control"
                    id="tmarks"
                    style={{ marginTop: "5px" }}
                    onChange={(e) => setTotalMarks(e.target.value)}
                    value={totalMarks}
                    placeholder="Enter Total Marks"
                  />
                </div>
                <div className="col-md-4" style={{ marginTop: "10px" }}>
                  <b> Class Position:</b>
                  <input
                    type="text"
                    style={{ marginTop: "5px" }}
                    className="form-control"
                    id="classposition"
                    onChange={(e) => setClassPosition(e.target.value)}
                    value={classPosition}
                    placeholder="Enter Class Position"
                  />
                </div>
                <div className="col-md-4" style={{ marginTop: "10px" }}>
                  <b> Attendance</b>
                  <input
                    style={{ marginTop: "5px" }}
                    type="number"
                    className="form-control"
                    id="attan"
                    onChange={(e) => setAttandance(e.target.value)}
                    value={attandance}
                    placeholder="Enter Attendance"
                  />
                </div>
                <div className="col-md-4" style={{ marginTop: "10px" }}>
                  <b> Out Of</b>
                  <input
                    type="number"
                    className="form-control"
                    style={{ marginTop: "5px" }}
                    id="tattan"
                    onChange={(e) => setTotalAttandance(e.target.value)}
                    value={totalAttandance}
                    placeholder="Enter Total Attendance"
                  />
                </div>
                <div className="col-md-12" style={{ marginTop: "10px" }}>
                  <b> Co-Curricular Activites</b>
                  <textarea
                    style={{ marginTop: "5px" }}
                    className="form-control"
                    id="coco"
                    onChange={(e) => setCurricularDetails(e.target.value)}
                    value={curricularDetails}
                    placeholder="Enter Co-Curricular Activities"
                  />
                </div>
                <div className="col-md-12" style={{ marginTop: "10px" }}>
                  <ReactToPrint
                    documentTitle={`School Leaving Certificate`}
                    onAfterPrint={handleAfterPrint}
                    onBeforeGetContent={handleOnBeforeGetContent}
                    onBeforePrint={handleBeforePrint}
                    removeAfterPrint={true}
                    trigger={() => (
                      <button
                        disabled={printloading ? false : true}
                        style={{ marginLeft: "10px", float: "right" }}
                        className="btn btn-primary w-105px"
                      >
                        Print
                      </button>
                    )}
                    content={() => componentRef.current}
                  />
                  <button
                    onClick={addNow}
                    className="btn btn-primary"
                    style={{ float: "right" }}
                    disabled={saveButton}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}

        {/* //for print///// */}
        <div
          ref={componentRef}
          id="printSection"
          style={{ visibility: "hidden" }}
          className="tablePrint"
        >
          <div
            className="mainContainer"
            style={{
              margin: "30px",
              border: "2px solid black",
              height: "1500px",
            }}
          >
            <div className="logocontainer" style={{ height: "250px" }}>
              <div>
                <center>
                  <img
                    // className='col-md-2'
                    style={{
                      height: 200,
                      width: 170,
                      display: "inline-block",
                      textAlign: "center",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                    src={icon}
                    alt="Logo"
                  />
                </center>
                <br />{" "}
                <p
                  style={{
                    display: "block",
                    textAlign: "center",
                    fontSize: "30px",
                    fontFamily: "Times New Roman",
                  }}
                >
                  <b>{unitName}</b>
                </p>
              </div>
            </div>
            <div
              style={{
                display: "block",
                textAlign: "center",
                marginTop: "30px",
              }}
            >
              <div style={{ border: "2px solid black", fontSize: "30px" }}>
                <p style={{ paddingTop: "10px" }}>
                  <b>School Leaving Certificate</b>
                </p>{" "}
              </div>
            </div>
            <div style={{ marginTop: "30px" }}>
              <label style={{ width: "180px", fontSize: "21px" }}>
                Serial No:{" "}
              </label>
              <label
                className="underline"
                style={{
                  fontSize: "21px",
                  width: "150px",
                  textAlign: "center",
                }}
              >
                {certificate_Id}
              </label>
              <label style={{ width: "380px" }}></label>
              <label
                style={{
                  width: "120px",
                  fontSize: "21px",
                }}
              >
                Reg. Boys:{" "}
              </label>
              <label
                className="underline"
                style={{
                  fontSize: "21px",
                  width: "150px",
                  textAlign: "center",
                }}
              >
                15983
              </label>
              <br />
              <label style={{ width: "180px", fontSize: "21px" }}>
                Admission No:
              </label>
              <label
                className="underline"
                style={{
                  fontSize: "21px",
                  width: "150px",
                  textAlign: "center",
                }}
              >
                {admission_id}
              </label>
              <label style={{ width: "380px" }}></label>
              <label
                style={{
                  width: "120px",
                  fontSize: "21px",
                }}
              >
                Reg. Girls:
              </label>
              <label
                className="underline"
                style={{
                  fontSize: "21px",
                  width: "150px",
                  textAlign: "center",
                }}
              >
                15495
              </label>

              <p style={{ fontSize: "25px", marginTop: "50px" }}>
                <span style={{ display: "inline-block", width: "300px" }}>
                  <i style={{ color: "black", fontSize: "23px" }}>
                    It is certified that:
                  </i>
                </span>{" "}
                <span
                  style={{
                    size: "10",
                    borderBottom: "5px black",
                    width: "300px",
                  }}
                >
                  <label
                    className="underline"
                    style={{
                      fontFamily: "Times New Roman",
                      width: "600px",
                      fontSize: "21px",
                    }}
                  >
                    <b>{studentName}</b>
                  </label>
                </span>
              </p>

              <p style={{ fontSize: "25px" }}>
                <span style={{ display: "inline-block", width: "300px" }}>
                  <i style={{ color: "black", fontSize: "21px" }}>
                    Son / Daughter of :
                  </i>{" "}
                </span>{" "}
                <span
                  style={{
                    size: "10",
                    borderBottom: "5px black",
                    width: "300px",
                  }}
                >
                  <label
                    className="underline"
                    style={{
                      fontFamily: "Times New Roman",
                      width: "600px",
                      fontSize: "21px",
                    }}
                  >
                    <b>{fatherName}</b>
                  </label>
                </span>
              </p>

              {showDistrict ? (
                <p style={{ fontSize: "25px" }}>
                  <span style={{ display: "inline-block", width: "150px" }}>
                    <i style={{ color: "black", fontSize: "21px" }}>Tehsil:</i>{" "}
                  </span>{" "}
                  <span
                    style={{
                      display: "inline-block",
                      width: "340px",
                      fontSize: "21px",
                      textAlign: "center",
                    }}
                  >
                    <label
                      className="underline"
                      style={{
                        fontSize: "21px",
                        // width: "150px",
                        textAlign: "center",
                      }}
                    >
                      {tehsil}
                    </label>
                  </span>{" "}
                  <span
                    style={{
                      display: "inline-block",
                      width: "150px",
                      textAlign: "start",
                    }}
                  >
                    <i style={{ color: "black", fontSize: "21px" }}>
                      District:{" "}
                    </i>
                  </span>{" "}
                  <label
                    className="underline"
                    style={{
                      fontSize: "21px",
                      width: "250px",
                      textAlign: "center",
                    }}
                  >
                    {district}
                  </label>
                </p>
              ) : null}
              <p style={{ fontSize: "25px" }}>
                <span style={{ display: "inline-block", width: "300px" }}>
                  <label
                    style={{
                      color: "black",
                      fontSize: "21px",
                      width: "300px",
                    }}
                  >
                    Whose Date of Birth is:
                  </label>
                </span>{" "}
                <span
                  style={{
                    display: "inline-block",
                    width: "200px",
                    fontSize: "20px",
                  }}
                >
                  {" "}
                  <label
                    className="underline"
                    style={{ width: "200px", textAlign: "center" }}
                  >
                    {dateOfBirth}
                  </label>
                </span>{" "}
                <i
                  style={{
                    color: "black",
                    fontSize: "21px",
                    marginLeft: "30px",
                  }}
                >
                  has been the student of this school
                </i>
              </p>

              <p style={{ fontSize: "25px" }}>
                <span style={{ display: "inline-block", width: "300px" }}>
                  <i style={{ color: "black", fontSize: "21px" }}>
                    Date of Birth in Words:
                  </i>{" "}
                </span>{" "}
                <label
                  className="underline"
                  style={{ fontSize: "21px", width: "600px" }}
                >
                  {dateOfBithInWords}
                </label>
              </p>

              <p style={{ fontSize: "25px" }}>
                <span style={{ display: "inline-block", width: "440px" }}>
                  <i style={{ color: "black", fontSize: "21px" }}>
                    He / She has study / promoted in class:
                  </i>
                </span>{" "}
                <label
                  className="underline"
                  style={{ fontSize: "21px", width: "450px" }}
                >
                  {currentClassName}
                </label>
              </p>
              <p style={{ fontSize: "25px" }}>
                <span style={{ display: "inline-block", width: "400px" }}>
                  <i style={{ color: "black", fontSize: "21px" }}>
                    He / She has Passed / Failed in class:
                  </i>
                </span>{" "}
                <span
                  style={{
                    display: "inline-block",
                    width: "100px",
                    fontSize: "21px",
                  }}
                >
                  <label
                    className="underline"
                    style={{ width: "100px", textAlign: "center" }}
                  >
                    {previousClass}
                  </label>
                </span>
              </p>
              <p style={{ fontSize: "25px" }}>
                <span style={{ display: "inline-block", width: "400px" }}>
                  <i style={{ color: "black", fontSize: "21px" }}>
                    Marks in Annual Examination:
                  </i>
                </span>{" "}
                <span
                  style={{
                    display: "inline-block",
                    width: "100px",
                    fontSize: "21px",
                  }}
                >
                  {" "}
                  <label
                    className="underline"
                    style={{ width: "100px", textAlign: "center" }}
                  >
                    {obtainMarks}
                  </label>{" "}
                </span>{" "}
                <span
                  style={{
                    display: "inline-block",
                    width: "150px",
                    textAlign: "center",
                  }}
                >
                  <i
                    style={{
                      color: "black",
                      fontSize: "21px",
                      textAlign: "center",
                    }}
                  >
                    Out Of
                  </i>{" "}
                </span>{" "}
                <span
                  style={{
                    display: "inline-block",
                    width: "100px",
                    fontSize: "21px",
                  }}
                >
                  <label
                    className="underline"
                    style={{ width: "100px", textAlign: "center" }}
                  >
                    {totalMarks}
                  </label>
                </span>
              </p>
              <p style={{ fontSize: "30px" }}>
                <b>
                  <i style={{ color: "black", fontSize: "23px" }}>
                    The Student Particular's are as following
                  </i>
                </b>
              </p>
              <p style={{ fontSize: "25px" }}>
                <span style={{ display: "inline-block", width: "300px" }}>
                  <i style={{ color: "black", fontSize: "21px" }}>
                    Date of Admission:
                  </i>{" "}
                </span>{" "}
                <span
                  style={{
                    display: "inline-block",
                    width: "200px",
                    fontSize: "21px",
                    textAlign: "center",
                  }}
                >
                  <label
                    className="underline"
                    style={{
                      fontSize: "21px",
                      width: "150px",
                      textAlign: "center",
                    }}
                  >
                    {admissionDate}
                  </label>
                </span>{" "}
                <span style={{ display: "inline-block", width: "320px" }}>
                  {" "}
                  <i style={{ color: "black", fontSize: "21px" }}>
                    {" "}
                    Date Of School Leaving{" "}
                  </i>
                </span>{" "}
                <label
                  className="underline"
                  style={{
                    fontSize: "21px",
                    width: "150px",
                    textAlign: "center",
                  }}
                >
                  {leavingDate}
                </label>
              </p>
              <p style={{ fontSize: "25px" }}>
                <span style={{ display: "inline-block", width: "300px" }}>
                  <i style={{ color: "black", fontSize: "21px" }}>
                    Attendances:
                  </i>{" "}
                </span>{" "}
                <span
                  style={{
                    display: "inline-block",
                    width: "200px",
                    fontSize: "21px",
                    textAlign: "center",
                  }}
                >
                  <label
                    className="underline"
                    style={{
                      fontSize: "21px",
                      width: "150px",
                      textAlign: "center",
                    }}
                  >
                    {attandance}
                  </label>
                </span>{" "}
                <span
                  style={{
                    display: "inline-block",
                    width: "320px",
                    textAlign: "start",
                  }}
                >
                  <i style={{ color: "black", fontSize: "21px" }}>
                    Total Attendances:{" "}
                  </i>
                </span>{" "}
                <label
                  className="underline"
                  style={{
                    fontSize: "21px",
                    width: "150px",
                    textAlign: "center",
                  }}
                >
                  {totalAttandance}
                </label>
              </p>
              <p style={{ fontSize: "25px" }}>
                <span style={{ display: "inline-block", width: "300px" }}>
                  <i style={{ color: "black", fontSize: "21px" }}>
                    Last Class:
                  </i>
                </span>{" "}
                <span
                  style={{
                    display: "inline-block",
                    width: "200px",
                    fontSize: "21px",
                    textAlign: "center",
                  }}
                >
                  <label
                    className="underline"
                    style={{
                      fontSize: "21px",
                      width: "150px",
                      textAlign: "center",
                    }}
                  >
                    {previousClass}
                  </label>
                </span>
                <span style={{ display: "inline-block", width: "330px" }}>
                  <i style={{ color: "black", fontSize: "21px" }}>
                    Dues Paid Till Date
                  </i>
                </span>
                <label
                  className="underline"
                  style={{
                    fontSize: "21px",
                    width: "150px",
                    textAlign: "center",
                  }}
                >
                  {getMonthName(duechallans)}
                </label>
              </p>
              <p style={{ fontSize: "21px" }}>
                <span style={{ display: "inline-block", width: "300px" }}>
                  <i style={{ color: "black", fontSize: "21px" }}>
                    Co-Curricular Activites
                  </i>
                </span>{" "}
                <label
                  className="underline"
                  style={{ fontSize: "21px", width: "670px" }}
                >
                  {curricularDetails}
                </label>
              </p>
              <p style={{ fontSize: "21px" }}>
                <span style={{ display: "inline-block", width: "300px" }}>
                  <i style={{ color: "black", fontSize: "21px" }}>
                    <b>Issue Date:</b>
                  </i>{" "}
                </span>{" "}
                <label
                  className="underline"
                  style={{ fontSize: "21px", width: "150px" }}
                >
                  {issueDate}
                </label>
              </p>

              <div></div>
            </div>
            <Modal show={editmodal} size="lg">
              <Modal.Header className="modal_header_style" closeButton>
                <Modal.Title
                  className="Modal_Heading_font"
                  id="contained-modal-title-vcenter"
                >
                  Pending Fee Challans
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Table responsive>
                  <thead>
                    <tr>
                      <th style={{ textAlign: "center" }} width="20%">
                        Challan No
                      </th>
                      <th style={{ textAlign: "center" }} width="40%">
                        Name
                      </th>
                      <th style={{ textAlign: "center" }} width="20%">
                        Fee Month
                      </th>
                      <th style={{ textAlign: "center" }} width="20%">
                        Payable
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {campuschallans.map((campus, index) => (
                      <tr key={index}>
                        <td>&nbsp;&nbsp;{campus.challan_no}</td>
                        <td>&nbsp;&nbsp;{campus.student.name}</td>
                        <td>
                          {getMonthName(
                            campus.fee_challan_details[0].fee_month
                          )}
                        </td>
                        <td>{campus.payable}</td>
                      </tr>
                    ))}
                  </tbody>
                  <tbody>
                    <tr>
                      <td></td>
                      <td></td>
                      <td> Net Fee:</td>
                      <td>{totalFee}</td>
                    </tr>
                  </tbody>
                </Table>

                <button
                  onClick={() => {
                    seteditmodal(false);
                    // setBestOption([]);
                    // setFruitOne([]);
                    // setChecked([]);
                    //  handleSearch();
                  }}
                  style={{ float: "right" }}
                  className="btn btn-danger"
                >
                  Close
                </button>
              </Modal.Body>
            </Modal>
          </div>
          <p
            style={{
              fontSize: "18px",
              marginTop: "-80px",
              marginLeft: "5px",
            }}
          >
            <span
              style={{
                display: "inline-block",
                width: "400px",
                textAlign: "center",
              }}
            >
              <label
                className="topline"
                style={{ width: "200px", textAlign: "center" }}
              >
                Prepared By:
              </label>
            </span>{" "}
            <span
              style={{
                display: "inline-block",
                width: "200px",
                marginLeft: "30px",
                textAlign: "center",
              }}
            >
              {" "}
              <label
                className="topline"
                style={{ width: "200px", textAlign: "center" }}
              >
                Checked By
              </label>{" "}
            </span>{" "}
            <span
              style={{
                display: "inline-block",
                width: "180px",
                textAlign: "center",
              }}
            ></span>{" "}
            <label
              className="topline"
              style={{ width: "180px", textAlign: "center" }}
            >
              Principal
            </label>
          </p>
          <p
            style={{
              fontSize: "18px",
              float: "right",
              marginRight: "40px",
              marginTop: "-10px",
            }}
          >
            info@darearqamsgd.edu.pk
          </p>
        </div>
      </Paper>
    </>
  );
};

export default SchoolLeavingCertificate;
