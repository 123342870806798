import { DownloadTableExcel } from "react-export-table-to-excel";
import React, { useEffect, useRef, useState } from "react";
import { Paper } from "@material-ui/core";
import { Divider, DatePicker } from "antd";
import { baseUrl } from "../../../../services/config";
import { Spinner, Table } from "react-bootstrap";
import { useAlert } from "react-alert";
import ReactToPrint from "react-to-print";
import { upperCase } from "lodash";
const { RangePicker } = DatePicker;

const pageStyle = `
  @page {
    size: A4 !important;
}
`;
const StudentTotalAdm = () => {
  const tableRef = useRef(null);
  const [selected, setSelected] = useState("session");
  const [showSession, setShowSession] = useState(true);
  const [showCampus, setShowCampus] = useState(false);
  const [showCampusClass, setShowCampusClass] = useState(false);
  const [showClass, setShowClass] = useState(false);
  const [showMonthly, setShowMonthly] = useState(false);
  const [showDate, setShowDate] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endtDate, setEndDate] = useState("");
  const [grandTotal, setGrandTotal] = useState(0);
  const [reportData, setreportData] = useState([]);
  const [contGrand, setCountGrand] = useState(0);
  const [amountGrand, setAmountGrand] = useState(0);
  const [maleGrand, setMaleGrand] = useState(0);
  const [femaleGrand, setFemaleGrand] = useState(0);
  const [lastTotal, setLastTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showInactiveWise, setshowInactiveWise] = useState(false);
  const [allsessions, setallsessions] = useState([]);
  const [currentsession, setcurrentsession] = useState("");

  let authtoken = localStorage.getItem("userToken");
  //for print
  const [printloading, setprintloading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");
  const componentRef = useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);
  const alert = useAlert();

  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);

  useEffect(() => {
    getAllSessions();
    getSettingData();
  }, []);

  const getAllSessions = () => {
    fetch(`${baseUrl}/session`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setallsessions([...data.payload]);
        data.payload.forEach((session, index) => {
          if (session.active_academic_year == "1") {
            setcurrentsession(session.id);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload);
        setUnitName(upperCase(data.payload.settings.unit_name));
        setIcon(data.payload.settings.logo_file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onRadioButtonClick = (e) => {
    const value = e.target.value;
    setSelected(e.target.value);

    if (value == "session") {
      setreportData("");
      setShowSession(true);
      setShowCampus(false);
      setShowCampusClass(false);
      setShowClass(false);
      setShowMonthly(false);
      setShowDate(false);
      setshowInactiveWise(false);
      setAmountGrand(0);
      setCountGrand(0);
      setGrandTotal(0);
      setLastTotal(0);
      setFemaleGrand(0);
      setMaleGrand(0);
    }
    if (value == "campus") {
      setreportData("");

      setShowSession(false);
      setShowCampus(true);
      setShowCampusClass(false);
      setShowClass(false);
      setShowMonthly(false);
      setShowDate(false);
      setshowInactiveWise(false);
      setAmountGrand(0);
      setCountGrand(0);
      setGrandTotal(0);
      setLastTotal(0);
      setFemaleGrand(0);
      setMaleGrand(0);
    }
    if (value == "campus-class") {
      setreportData("");

      setShowSession(false);
      setShowCampus(false);
      setShowCampusClass(true);
      setShowClass(false);
      setShowMonthly(false);
      setShowDate(false);
      setshowInactiveWise(false);
      setAmountGrand(0);
      setGrandTotal(0);
      setCountGrand(0);
    }
    if (value == "class") {
      setreportData("");

      setShowSession(false);
      setShowCampus(false);
      setShowCampusClass(false);
      setShowClass(true);
      setShowMonthly(false);
      setShowDate(false);
      setshowInactiveWise(false);
      setAmountGrand(0);
      setCountGrand(0);
      setLastTotal(0);
      setFemaleGrand(0);
      setMaleGrand(0);
    }
    if (value == "month") {
      setreportData("");

      setShowSession(false);
      setShowCampus(false);
      setShowCampusClass(false);
      setShowClass(false);
      setShowMonthly(true);
      setShowDate(false);
      setshowInactiveWise(false);
      setGrandTotal(0);
      setLastTotal(0);
      setFemaleGrand(0);
      setMaleGrand(0);
    }
    if (value == "date") {
      setreportData("");

      setShowSession(false);
      setShowCampus(false);
      setShowCampusClass(false);
      setShowClass(false);
      setShowMonthly(false);
      setShowDate(true);
      setshowInactiveWise(false);
      setGrandTotal(0);
      setLastTotal(0);
      setFemaleGrand(0);
      setMaleGrand(0);
    }
    if (value == "inactive") {
      setreportData("");
      setShowSession(false);
      setShowCampus(false);
      setShowCampusClass(false);
      setShowClass(false);
      setShowMonthly(false);
      setShowDate(false);
      setshowInactiveWise(true);
      setGrandTotal(0);
      setLastTotal(0);
      setFemaleGrand(0);
      setMaleGrand(0);
    }
  };

  const handlePickerChnage = (date, dateString) => {
    console.log(date, dateString, "this is ");
    setStartDate(dateString[0]);
    setEndDate(dateString[1]);
  };
  const handleShow = () => {
    if (
      startDate == "" ||
      endtDate == "" ||
      startDate == null ||
      endtDate == null
    ) {
      alert.error("Start Date and End Date is required");
      return;
    }
    setLoading(true);
    const data = {
      year_id: currentsession,
      start_date: startDate,
      end_date: endtDate,
      session_wise: showSession ? 1 : "",
      campus_wise: showCampus ? 1 : "",
      campus_class_wise: showCampusClass ? 1 : "",
      class_wise: showClass ? 1 : "",
      monthly_fees_wise: showMonthly ? 1 : "",
      date_wise: showDate ? 1 : "",
      inactive: showInactiveWise ? 1 : "",
    };
    fetch(`${baseUrl}/student_total_admissions_reports`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },

      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);

        // console.log(data);
        if (data.metadata.success) {
          console.log(data.payload, "this");
          setreportData(data.payload);
          if (showClass) {
            getFeeGrand(data.payload);
          }
          if (showMonthly) {
            getCountGrand(data.payload);
            getAmountGrand(data.payload);
          }
          if (showCampusClass) {
            getMaleTotal(data.payload);
            getfeMaleTotal(data.payload);
            getlastTotal(data.payload);

            // console.log(getCampusClassTotal(data.payload));
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("err is re", err);
      });
  };
  const getFeeGrand = (array) => {
    if (array) {
      let sum = 0;
      array.map((item) => {
        item.Classes.map((subitem) => {
          sum += parseInt(subitem.total_admissions);
        });
      });
      setGrandTotal(sum);
    }
  };
  const getCountGrand = (array) => {
    let sum = 0;
    array.map((item) => {
      item.fees.map((subitem) => {
        sum += parseInt(subitem.count);
      });
    });
    setCountGrand(sum);
  };
  const getAmountGrand = (array) => {
    let sum = 0;
    array.map((item) => {
      item.fees.map((subitem) => {
        sum += Number(subitem.count) * Number(subitem.amount);
      });
    });
    setAmountGrand(sum);
  };
  const getMaleTotal = (array) => {
    let sum = 0;
    array.map((item) => {
      item.Classes.map((subitem) => {
        sum += parseInt(subitem.report[0].male);
      });
    });
    setMaleGrand(sum);
  };
  const getfeMaleTotal = (array) => {
    let sum = 0;
    array.map((item) => {
      item.Classes.map((subitem) => {
        sum += parseInt(subitem.report[0].female);
      });
    });
    setFemaleGrand(sum);
  };
  const getlastTotal = (array) => {
    let sum = 0;
    array.map((item) => {
      item.Classes.map((subitem) => {
        sum += parseInt(subitem.report[0].total_admissions);
      });
    });
    setLastTotal(sum);
  };

  const getCampusMale = (array) => {
    if (array) {
      let sum = 0;
      array.map((item) => {
        item.report.map((sub) => {
          sum += parseInt(sub.male);
        });
      });
      return sum;
    } else {
      return 0;
    }
  };
  const getCampusFemale = (array) => {
    if (array) {
      let sum = 0;
      array.map((item) => {
        item.report.map((sub) => {
          sum += parseInt(sub.female);
        });
      });
      return sum;
    } else {
      return 0;
    }
  };
  const getCampusTotal = (array) => {
    if (array) {
      let sum = 0;
      array.map((item) => {
        item.report.map((sub) => {
          sum += parseInt(sub.total_admissions);
        });
      });
      return sum;
    } else {
      return 0;
    }
  };

  const getCampusClassTotal = (array) => {
    if (array) {
      let sum = 0;
      array.Classes.map((classes_report) => {
        classes_report.report.map((classData) => {
          sum += parseInt(classData.total_admissions);
        });
      });
      return sum;
    } else {
      return 0;
    }
    // return 0;
  };

  const getCampusSectionTotal = (array) => {
    if (array) {
      let sum = 0;
      array.Classes.map((classes_report) => {
        sum += parseInt(classes_report.total_admissions);
      });
      return sum;
    } else {
      return 0;
    }
    // return 0;
  };
  //for print
  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);
  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setprintloading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setprintloading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setprintloading, setText]);
  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);
  return (
    <>
      <Paper
        style={{
          marginTop: "10px",
          marginBottom: "10px",
          padding: "15px",
        }}
      >
        <div className="row">
          <div className="col-md-3">
            <input
              checked={selected == "session"}
              onChange={(e) => onRadioButtonClick(e)}
              defaultChecked
              type="radio"
              id="Session"
              name="contact"
              value="session"
            />
            <label for="Session">Session Wise</label>
          </div>

          <div className="col-md-3">
            <input
              checked={selected == "campus"}
              onChange={(e) => onRadioButtonClick(e)}
              type="radio"
              id="Campus"
              name="contact"
              value="campus"
            />
            <label for="Campus">Campus Wise</label>
          </div>
          <div className="col-md-3">
            <input
              checked={selected == "campus-class"}
              onChange={(e) => onRadioButtonClick(e)}
              type="radio"
              id="campus-class"
              name="contact"
              value="campus-class"
            />
            <label for="campus-class">Campus Class Wise</label>
          </div>
          <div className="col-md-3">
            <input
              checked={selected == "class"}
              onChange={(e) => onRadioButtonClick(e)}
              type="radio"
              id="Class"
              name="contact"
              value="class"
            />
            <label for="Class">Class Wise</label>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-md-3">
            <input
              checked={selected == "month"}
              onChange={(e) => onRadioButtonClick(e)}
              type="radio"
              id="month"
              name="contact"
              value="month"
            />
            <label for="month">Monthly Fee Wise</label>
          </div>
          <div className="col-md-3">
            <input
              checked={selected == "date"}
              onChange={(e) => onRadioButtonClick(e)}
              type="radio"
              id="date"
              name="contact"
              value="date"
            />
            <label for="date">Date Wise</label>
          </div>
          <div className="col-md-3">
            <input
              checked={selected == "inactive"}
              onChange={(e) => onRadioButtonClick(e)}
              type="radio"
              id="inactive"
              name="contact"
              value="inactive"
            />
            <label for="inactive">Inactive New Admissions</label>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-md-3">
            <label className="form-control-label">
              <b>Select Session</b>
            </label>
            <select
              onChange={(e) => setcurrentsession(e.target.value)}
              class="form-select"
              style={{ height: 40 }}
              value={currentsession}
              aria-label="Default select example"
            >
              <option value="">Select Session</option>
              {allsessions.map((session) => (
                <option key={session.id} value={session.id}>
                  {session.year}
                </option>
              ))}
            </select>
          </div>

          <div className="col-md-3">
            <label className="form-control-label">
              <b>Select Start and End Date</b>
            </label>
            <RangePicker
              onChange={handlePickerChnage}
              style={{ height: "40px", width: "100%" }}
            />
          </div>

          <div className="col-md-9 mt-9">
            <button
              disabled={loading}
              className="btn btn-primary mx-2"
              onClick={handleShow}
            >
              Show
            </button>
            <ReactToPrint
              documentTitle={`salary slip Report`}
              onAfterPrint={handleAfterPrint}
              onBeforeGetContent={handleOnBeforeGetContent}
              onBeforePrint={handleBeforePrint}
              removeAfterPrint={true}
              pageStyle={pageStyle}
              trigger={() => (
                <button
                  disabled={printloading ? true : false}
                  className="btn btn-primary w-100px mx-2"
                >
                  {printloading ? <span>Printing..</span> : "Print"}
                </button>
              )}
              content={() => componentRef.current}
            />
            <DownloadTableExcel
              filename="Student Total Admission Report"
              sheet="Student Total Admission Report"
              currentTableRef={tableRef.current}
            >
              <button className="btn btn-primary mx-2">Export</button>
            </DownloadTableExcel>
          </div>
        </div>

        {/* //session wise data is here */}
        {showSession ? (
          <div id="tableboot" className="mt-5">
            <Table responsive>
              <thead>
                <tr id="tbl">
                  <th className="text-center">Month & Year</th>
                  <th className="text-center">Male</th>
                  <th className="text-center">Female</th>
                  <th className="text-center">Total Admissions</th>
                </tr>
              </thead>

              <tbody>
                {reportData && (
                  <>
                    {reportData.map((item, index) => (
                      <tr id="tbl">
                        <td
                          style={{
                            color: "black",
                            textAlign: "center",
                          }}
                        >
                          {item.month_year}
                        </td>
                        <td
                          style={{
                            color: "black",
                            textAlign: "center",
                          }}
                        >
                          {item.male}
                        </td>
                        <td
                          style={{
                            color: "black",
                            textAlign: "center",
                          }}
                        >
                          {item.female}
                        </td>
                        <td
                          style={{
                            color: "black",
                            textAlign: "center",
                          }}
                        >
                          {item.total_admissions}
                        </td>
                      </tr>
                    ))}
                    <tr id="tbl">
                      <td
                        className="text-primary"
                        style={{
                          textAlign: "center",
                          fontSize: "1.2rem",
                          fontWeight: "bold",
                        }}
                      >
                        Grand Total
                      </td>
                      <td
                        className="text-primary"
                        style={{
                          textAlign: "center",
                          fontSize: "1.2rem",
                          fontWeight: "bold",
                        }}
                      >
                        {reportData.reduce((a, c) => a + parseInt(c.male), 0)}
                      </td>
                      <td
                        className="text-primary"
                        style={{
                          textAlign: "center",
                          fontSize: "1.2rem",
                          fontWeight: "bold",
                        }}
                      >
                        {reportData.reduce((a, c) => a + parseInt(c.female), 0)}
                      </td>
                      <td
                        className="text-primary"
                        style={{
                          textAlign: "center",
                          fontSize: "1.2rem",
                          fontWeight: "bold",
                        }}
                      >
                        {reportData.reduce((a, c) => a + parseInt(c.male), 0) +
                          reportData.reduce(
                            (a, c) => a + parseInt(c.female),
                            0
                          )}
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </Table>
          </div>
        ) : null}
        {/* campus wise data is here */}
        {showCampus ? (
          <div id="tableboot" className="mt-5">
            <Table responsive>
              <thead>
                <tr id="tbl">
                  <th className="text-center">Month & Year</th>
                  <th className="text-center">Male</th>
                  <th className="text-center">Female</th>
                  <th className="text-center">Total Admissions</th>
                </tr>
              </thead>

              <tbody>
                {reportData &&
                  reportData?.map((campus) => (
                    <>
                      {campus.report.length > 0 ? (
                        <div>
                          <h4 className="text-primary mt-3">{campus.Campus}</h4>
                        </div>
                      ) : (
                        ""
                      )}
                      {campus.report.length > 0
                        ? campus.report.map((item, index) => (
                            <>
                              <tr id="tbl">
                                <td
                                  style={{
                                    color: "black",
                                    textAlign: "center",
                                  }}
                                >
                                  {item.month_year}
                                </td>
                                <td
                                  style={{
                                    color: "black",
                                    textAlign: "center",
                                  }}
                                >
                                  {item.male}
                                </td>
                                <td
                                  style={{
                                    color: "black",
                                    textAlign: "center",
                                  }}
                                >
                                  {item.female}
                                </td>
                                <td
                                  style={{
                                    color: "black",
                                    textAlign: "center",
                                  }}
                                >
                                  {item.total_admissions}
                                </td>
                              </tr>
                            </>
                          ))
                        : ""}
                    </>
                  ))}
                <>
                  <tr id="tbl">
                    <td
                      className="text-primary"
                      style={{
                        textAlign: "center",
                        fontSize: "1.2rem",
                        fontWeight: "bold",
                      }}
                    >
                      Grand Total
                    </td>
                    <td
                      className="text-primary"
                      style={{
                        textAlign: "center",
                        fontSize: "1.2rem",
                        fontWeight: "bold",
                      }}
                    >
                      {getCampusMale(reportData)}
                    </td>
                    <td
                      className="text-primary"
                      style={{
                        textAlign: "center",
                        fontSize: "1.2rem",
                        fontWeight: "bold",
                      }}
                    >
                      {getCampusFemale(reportData)}
                    </td>
                    <td
                      className="text-primary"
                      style={{
                        textAlign: "center",
                        fontSize: "1.2rem",
                        fontWeight: "bold",
                      }}
                    >
                      {getCampusTotal(reportData)}
                    </td>
                  </tr>
                </>
              </tbody>
            </Table>
          </div>
        ) : null}
        {/* campus class wise data is here */}
        {showCampusClass ? (
          <div id="tableboot" className="mt-5">
            <Table responsive>
              <thead>
                <tr id="tbl">
                  <th className="text-center">Class Name</th>
                  <th className="text-center">Male</th>
                  <th className="text-center">Female</th>
                  <th className="text-center">Total Admissions</th>
                </tr>
              </thead>

              <tbody>
                {reportData &&
                  reportData?.map((campus) => (
                    <>
                      {getCampusClassTotal(campus) > 0 && (
                        <div>
                          <h4 className="text-primary mt-3">{campus.Campus}</h4>
                        </div>
                      )}
                      {campus.Classes.map((item, index) =>
                        item.report[0].total_admissions == "0" ? (
                          ""
                        ) : (
                          <>
                            <tr id="tbl">
                              <td
                                style={{
                                  color: "black",
                                  textAlign: "center",
                                }}
                              >
                                {item.Class.name}
                              </td>
                              <td
                                style={{
                                  color: "black",
                                  textAlign: "center",
                                }}
                              >
                                {item.report[0].male}
                              </td>
                              <td
                                style={{
                                  color: "black",
                                  textAlign: "center",
                                }}
                              >
                                {item.report[0].female}
                              </td>
                              <td
                                style={{
                                  color: "black",
                                  textAlign: "center",
                                }}
                              >
                                {item.report[0].total_admissions}
                              </td>
                            </tr>
                          </>
                        )
                      )}
                      {getCampusClassTotal(campus) > 0 && (
                        <tr id="tbl">
                          <td
                            className="text-primary"
                            style={{
                              textAlign: "center",
                              fontSize: "1.2rem",
                            }}
                          >
                            Sub Total
                          </td>
                          <td
                            className="text-primary"
                            style={{
                              textAlign: "center",
                              fontSize: "1.2rem",
                            }}
                          >
                            {campus.Classes.reduce(
                              (a, c) => a + parseInt(c.report[0].male),
                              0
                            )}
                          </td>
                          <td
                            className="text-primary"
                            style={{
                              textAlign: "center",
                              fontSize: "1.2rem",
                            }}
                          >
                            {campus.Classes.reduce(
                              (a, c) => a + parseInt(c.report[0].female),
                              0
                            )}
                          </td>
                          <td
                            className="text-primary"
                            style={{
                              textAlign: "center",
                              fontSize: "1.2rem",
                            }}
                          >
                            {campus.Classes.reduce(
                              (a, c) =>
                                a + parseInt(c.report[0].total_admissions),
                              0
                            )}
                          </td>
                        </tr>
                      )}
                    </>
                  ))}
                <tr
                  id="tbl"
                  style={{
                    borderTop: "1px solid",
                  }}
                >
                  <td
                    className="text-primary"
                    style={{
                      textAlign: "center",
                      fontSize: "1.2rem",
                      fontWeight: "bold",
                    }}
                  >
                    Grand Total
                  </td>
                  <td
                    className="text-primary"
                    style={{
                      textAlign: "center",
                      fontSize: "1.2rem",
                      fontWeight: "bold",
                    }}
                  >
                    {maleGrand}
                  </td>
                  <td
                    className="text-primary"
                    style={{
                      textAlign: "center",
                      fontSize: "1.2rem",
                      fontWeight: "bold",
                    }}
                  >
                    {femaleGrand}
                  </td>
                  <td
                    className="text-primary"
                    style={{
                      textAlign: "center",
                      fontSize: "1.2rem",
                      fontWeight: "bold",
                    }}
                  >
                    {lastTotal}
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        ) : null}
        {/*  class wise data is here */}
        {showClass ? (
          <div id="tableboot" className="mt-5">
            <Table responsive>
              <thead>
                <tr id="tbl">
                  <th className="text-center">Class Name</th>
                  <th className="text-center">Total Admissions</th>
                </tr>
              </thead>

              <tbody>
                {reportData &&
                  reportData?.map((month) => (
                    <>
                      <div>
                        <h4 className="text-primary mt-3">
                          {month.month_year}
                        </h4>
                      </div>

                      {month.Classes.map((item, index) =>
                        item.Class != null && item.total_admissions != 0 ? (
                          <>
                            <tr id="tbl">
                              <td
                                style={{
                                  color: "black",
                                  textAlign: "center",
                                }}
                              >
                                {item.Class.name}
                              </td>
                              <td
                                style={{
                                  color: "black",
                                  textAlign: "center",
                                }}
                              >
                                {item.total_admissions}
                              </td>
                            </tr>
                          </>
                        ) : (
                          ""
                        )
                      )}
                      {month?.Classes?.total_admissions != 0 ? (
                        <div
                          style={{
                            width: "146%",
                            textAlign: "end",
                          }}
                        >
                          <h5>
                            Sub Total :{" "}
                            {month.Classes.reduce(
                              (a, c) => a + parseInt(c.total_admissions),
                              0
                            )}
                          </h5>
                        </div>
                      ) : null}
                    </>
                  ))}
              </tbody>
            </Table>
            <div
              style={{
                width: "78%",
                textAlign: "end",
              }}
            >
              <h4 className="text-primary">Grand Total:{grandTotal}</h4>
            </div>
          </div>
        ) : null}
        {/* monthy fee wise ................... */}
        {showMonthly ? (
          <div id="tableboot" className="mt-5">
            <Table responsive>
              <thead>
                <tr id="tbl">
                  <th className="text-center">Monthly Fee</th>
                  <th className="text-center">No of Students</th>
                  <th className="text-center">Amount</th>
                </tr>
              </thead>

              <tbody>
                {reportData &&
                  reportData?.map((month) => (
                    <>
                      <div>
                        <h4 className="text-primary mt-3">
                          {month.month_year}
                        </h4>
                      </div>
                      {month.fees.map((item, index) => (
                        <>
                          <tr id="tbl">
                            <td
                              style={{
                                color: "black",
                                textAlign: "center",
                              }}
                            >
                              {item.amount}
                            </td>
                            <td
                              style={{
                                color: "black",
                                textAlign: "center",
                              }}
                            >
                              {item.count}
                            </td>
                            <td
                              style={{
                                color: "black",
                                textAlign: "center",
                              }}
                            >
                              {Number(item.count) * Number(item.amount)}
                            </td>
                          </tr>
                        </>
                      ))}
                      <tr id="tbl">
                        <td
                          className="text-primary"
                          style={{
                            textAlign: "center",
                            fontSize: "1.2rem",
                          }}
                        >
                          Sub Total
                        </td>
                        <td
                          className="text-primary"
                          style={{
                            textAlign: "center",
                            fontSize: "1.2rem",
                          }}
                        >
                          {month.fees.reduce(
                            (a, c) => a + parseInt(c.count),
                            0
                          )}
                        </td>
                        <td
                          className="text-primary"
                          style={{
                            textAlign: "center",
                            fontSize: "1.2rem",
                          }}
                        >
                          {month.fees.reduce(
                            (a, c) => a + Number(c.count) * Number(c.amount),
                            0
                          )}
                        </td>
                      </tr>
                    </>
                  ))}
                <tr id="tbl">
                  <td
                    className="text-primary"
                    style={{
                      textAlign: "center",
                      fontSize: "1.2rem",
                      fontWeight: "bold",
                    }}
                  >
                    Grand Total
                  </td>
                  <td
                    className="text-primary"
                    style={{
                      textAlign: "center",
                      fontSize: "1.2rem",
                      fontWeight: "bold",
                    }}
                  >
                    {contGrand}
                  </td>
                  <td
                    className="text-primary"
                    style={{
                      textAlign: "center",
                      fontSize: "1.2rem",
                      fontWeight: "bold",
                    }}
                  >
                    {amountGrand}
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        ) : null}
        {/* //date wise data is here */}
        {showDate ? (
          <div id="tableboot" className="mt-5">
            <Table responsive>
              <thead>
                <tr id="tbl">
                  <th className="text-center">Date</th>
                  <th className="text-center">Male</th>
                  <th className="text-center">Female</th>
                  <th className="text-center">Total Admissions</th>
                </tr>
              </thead>

              <tbody>
                {reportData && (
                  <>
                    {reportData.map((item, index) => (
                      <tr id="tbl">
                        <td
                          style={{
                            color: "black",
                            textAlign: "center",
                          }}
                        >
                          {item.date}
                        </td>
                        <td
                          style={{
                            color: "black",
                            textAlign: "center",
                          }}
                        >
                          {item.male}
                        </td>
                        <td
                          style={{
                            color: "black",
                            textAlign: "center",
                          }}
                        >
                          {item.female}
                        </td>
                        <td
                          style={{
                            color: "black",
                            textAlign: "center",
                          }}
                        >
                          {item.total_admissions}
                        </td>
                      </tr>
                    ))}
                    <tr id="tbl">
                      <td
                        className="text-primary"
                        style={{
                          textAlign: "center",
                          fontSize: "1.2rem",
                          fontWeight: "bold",
                        }}
                      >
                        Grand Total
                      </td>
                      <td
                        className="text-primary"
                        style={{
                          textAlign: "center",
                          fontSize: "1.2rem",
                          fontWeight: "bold",
                        }}
                      >
                        {reportData.reduce((a, c) => a + parseInt(c.male), 0)}
                      </td>
                      <td
                        className="text-primary"
                        style={{
                          textAlign: "center",
                          fontSize: "1.2rem",
                          fontWeight: "bold",
                        }}
                      >
                        {reportData.reduce((a, c) => a + parseInt(c.female), 0)}
                      </td>
                      <td
                        className="text-primary"
                        style={{
                          textAlign: "center",
                          fontSize: "1.2rem",
                          fontWeight: "bold",
                        }}
                      >
                        {reportData.reduce((a, c) => a + parseInt(c.male), 0) +
                          reportData.reduce(
                            (a, c) => a + parseInt(c.female),
                            0
                          )}
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </Table>
          </div>
        ) : null}
        {/* //Inactive wise data is here */}
        {showInactiveWise ? (
          <div id="tableboot" className="mt-5">
            <Table responsive>
              <thead>
                <tr id="tbl">
                  {/* <th className="text-center">Month & Year</th> */}
                  <th className="text-center">Male</th>
                  <th className="text-center">Female</th>
                  <th className="text-center">Total Admissions</th>
                </tr>
              </thead>

              <tbody>
                {/* {reportData &&
                  reportData.map((item, index) => ( */}
                <tr id="tbl">
                  {/* <td style={{ color: "black", textAlign: "center" }}>
                        {item.month_year}
                      </td> */}
                  <td
                    style={{
                      color: "black",
                      textAlign: "center",
                    }}
                  >
                    {reportData.male}
                  </td>
                  <td
                    style={{
                      color: "black",
                      textAlign: "center",
                    }}
                  >
                    {reportData.female}
                  </td>
                  <td
                    style={{
                      color: "black",
                      textAlign: "center",
                    }}
                  >
                    {reportData.total_admissions}
                  </td>
                </tr>
                {/* ))} */}
                {/* <tr id="tbl">
                                <td
                                className="text-primary"
                                style={{
                                  textAlign: "center",
                                  fontSize: "1.2rem",
                                  fontWeight: "bold",
                                }}
                                >
                                 Grand Total
                                </td>
                                <td
                                  className="text-primary"
                                  style={{
                                    textAlign: "center",
                                    fontSize: "1.2rem",
                                    fontWeight: "bold",
                                  }}
                                >
                                 { reportData.reduce((a, c) => a +parseInt(c.male), 0)}
                                </td>
                                <td
                                  className="text-primary"
                                  style={{
                                    textAlign: "center",
                                    fontSize: "1.2rem",
                                    fontWeight: "bold",
                                  }}
                                >
                                 { reportData.reduce((a, c) => a +parseInt(c.female), 0)}
                                 
                                </td>
                                <td
                                 className="text-primary"
                                 style={{
                                   textAlign: "center",
                                   fontSize: "1.2rem",
                                   fontWeight: "bold",
                                 }}
                                >
                                  { reportData.reduce((a, c) => a +parseInt(c.male), 0)+reportData.reduce((a, c) => a +parseInt(c.female), 0)}
                                </td>
                              </tr> */}
              </tbody>
            </Table>
          </div>
        ) : null}
        {/* //for print///// */}

        <div className="row justify-content-center">
          {loading && (
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          )}
        </div>

        <div
          ref={componentRef}
          id="printSection"
          style={{ visibility: "hidden" }}
          className="tablePrint"
        >
          <div className="logocontainer" style={{ height: "130px" }}>
            <div className="d-flex">
              <div className="">
                <img
                  style={{
                    height: 110,
                    width: 110,
                    pasection_compareingLeft: "20px",
                  }}
                  src={icon}
                  alt="logo"
                />
              </div>
              <div
                style={{
                  pasection_compareingTop: "10px",
                  marginLeft: "20px",
                }}
              >
                <h1>{unitName}</h1>
                <h3 style={{ fontSize: "20px" }}>
                  Student Total Admissin Report
                </h3>
              </div>
            </div>
          </div>
          <div ref={tableRef}>
            {showSession ? (
              <div id="tableboot">
                <Table
                  responsive
                  style={{
                    whiteSpace: "normal",
                    padding: "1px",
                    fontSize: "18px",
                  }}
                >
                  <thead
                    style={{
                      whiteSpace: "normal",
                      padding: "1px",
                      fontSize: "18px",
                    }}
                  >
                    <tr
                      id="tbl"
                      style={{
                        whiteSpace: "normal",
                        padding: "1px",
                        fontSize: "18px",
                      }}
                    >
                      <th
                        className="text-center"
                        style={{
                          whiteSpace: "normal",
                          padding: "1px",
                          fontSize: "18px",
                        }}
                      >
                        Month & Year
                      </th>
                      <th
                        className="text-center"
                        style={{
                          whiteSpace: "normal",
                          padding: "1px",
                          fontSize: "18px",
                        }}
                      >
                        Male
                      </th>
                      <th
                        className="text-center"
                        style={{
                          whiteSpace: "normal",
                          padding: "1px",
                          fontSize: "18px",
                        }}
                      >
                        Female
                      </th>
                      <th
                        className="text-center"
                        style={{
                          whiteSpace: "normal",
                          padding: "1px",
                          fontSize: "18px",
                        }}
                      >
                        Total Admissions
                      </th>
                    </tr>
                  </thead>

                  <tbody
                    style={{
                      whiteSpace: "normal",
                      padding: "1px",
                      fontSize: "18px",
                    }}
                  >
                    {reportData && (
                      <>
                        {reportData.map((item, index) => (
                          <tr
                            id="tbl"
                            style={{
                              whiteSpace: "normal",
                              padding: "1px",
                              fontSize: "18px",
                            }}
                          >
                            <td
                              style={{
                                color: "black",
                                textAlign: "center",

                                whiteSpace: "normal",
                                padding: "1px",
                                fontSize: "18px",
                              }}
                            >
                              {item.month_year}
                            </td>
                            <td
                              style={{
                                color: "black",
                                textAlign: "center",
                                whiteSpace: "normal",
                                padding: "1px",
                                fontSize: "18px",
                              }}
                            >
                              {item.male}
                            </td>
                            <td
                              style={{
                                color: "black",
                                textAlign: "center",
                                whiteSpace: "normal",
                                padding: "1px",
                                fontSize: "18px",
                              }}
                            >
                              {item.female}
                            </td>
                            <td
                              style={{
                                color: "black",
                                textAlign: "center",
                                whiteSpace: "normal",
                                padding: "1px",
                                fontSize: "18px",
                              }}
                            >
                              {item.total_admissions}
                            </td>
                          </tr>
                        ))}
                        <tr id="tbl">
                          <td
                            className="text-primary"
                            style={{
                              textAlign: "center",
                              fontWeight: "bold",
                              whiteSpace: "normal",
                              padding: "1px",
                              fontSize: "18px",
                            }}
                          >
                            Grand Total
                          </td>
                          <td
                            className="text-primary"
                            style={{
                              textAlign: "center",
                              fontWeight: "bold",
                              whiteSpace: "normal",
                              padding: "1px",
                              fontSize: "18px",
                            }}
                          >
                            {reportData.reduce(
                              (a, c) => a + parseInt(c.male),
                              0
                            )}
                          </td>
                          <td
                            className="text-primary"
                            style={{
                              textAlign: "center",
                              fontWeight: "bold",
                              whiteSpace: "normal",
                              padding: "1px",
                              fontSize: "18px",
                            }}
                          >
                            {reportData.reduce(
                              (a, c) => a + parseInt(c.female),
                              0
                            )}
                          </td>
                          <td
                            className="text-primary"
                            style={{
                              textAlign: "center",
                              fontWeight: "bold",
                              whiteSpace: "normal",
                              padding: "1px",
                              fontSize: "18px",
                            }}
                          >
                            {reportData.reduce(
                              (a, c) => a + parseInt(c.male),
                              0
                            ) +
                              reportData.reduce(
                                (a, c) => a + parseInt(c.female),
                                0
                              )}
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </Table>
              </div>
            ) : null}
            {/* campus wise data is here */}
            {showCampus ? (
              <div id="tableboot">
                <Table
                  responsive
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    whiteSpace: "normal",
                    padding: "1px",
                    fontSize: "18px",
                  }}
                >
                  <thead
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      whiteSpace: "normal",
                      padding: "1px",
                      fontSize: "18px",
                    }}
                  >
                    <tr
                      id="tbl"
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        whiteSpace: "normal",
                        padding: "1px",
                        fontSize: "18px",
                      }}
                    >
                      <th
                        style={{
                          textAlign: "center",
                          fontWeight: "bold",
                          whiteSpace: "normal",
                          padding: "1px",
                          fontSize: "18px",
                        }}
                        className="text-center"
                      >
                        Month & Year
                      </th>
                      <th
                        style={{
                          textAlign: "center",
                          fontWeight: "bold",
                          whiteSpace: "normal",
                          padding: "1px",
                          fontSize: "18px",
                        }}
                        className="text-center"
                      >
                        Male
                      </th>
                      <th
                        style={{
                          textAlign: "center",
                          fontWeight: "bold",
                          whiteSpace: "normal",
                          padding: "1px",
                          fontSize: "18px",
                        }}
                        className="text-center"
                      >
                        Female
                      </th>
                      <th
                        style={{
                          textAlign: "center",
                          fontWeight: "bold",
                          whiteSpace: "normal",
                          padding: "1px",
                          fontSize: "18px",
                        }}
                        className="text-center"
                      >
                        Total Admissions
                      </th>
                    </tr>
                  </thead>

                  <tbody
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      whiteSpace: "normal",
                      padding: "1px",
                      fontSize: "18px",
                    }}
                  >
                    {reportData &&
                      reportData?.map((campus) => (
                        <>
                          {campus.report.length > 0 ? (
                            <div>
                              <h4
                                style={{
                                  textAlign: "center",
                                  fontWeight: "bold",
                                  whiteSpace: "normal",
                                  padding: "1px",
                                  fontSize: "18px",
                                }}
                                className="text-primary mt-3"
                              >
                                {campus.Campus}
                              </h4>
                            </div>
                          ) : (
                            ""
                          )}
                          {campus.report.length > 0
                            ? campus.report.map((item, index) => (
                                <>
                                  <tr
                                    style={{
                                      textAlign: "center",
                                      fontWeight: "bold",
                                      whiteSpace: "normal",
                                      padding: "1px",
                                      fontSize: "18px",
                                    }}
                                    id="tbl"
                                  >
                                    <td
                                      style={{
                                        color: "black",
                                        textAlign: "center",
                                        fontWeight: "bold",
                                        whiteSpace: "normal",
                                        padding: "1px",
                                        fontSize: "18px",
                                      }}
                                    >
                                      {item.month_year}
                                    </td>
                                    <td
                                      style={{
                                        color: "black",
                                        textAlign: "center",
                                        fontWeight: "bold",
                                        whiteSpace: "normal",
                                        padding: "1px",
                                        fontSize: "18px",
                                      }}
                                    >
                                      {item.male}
                                    </td>
                                    <td
                                      style={{
                                        color: "black",
                                        textAlign: "center",
                                        fontWeight: "bold",
                                        whiteSpace: "normal",
                                        padding: "1px",
                                        fontSize: "18px",
                                      }}
                                    >
                                      {item.female}
                                    </td>
                                    <td
                                      style={{
                                        color: "black",
                                        textAlign: "center",
                                        fontWeight: "bold",
                                        whiteSpace: "normal",
                                        padding: "1px",
                                        fontSize: "18px",
                                      }}
                                    >
                                      {item.total_admissions}
                                    </td>
                                  </tr>
                                </>
                              ))
                            : ""}
                        </>
                      ))}
                    <>
                      <tr
                        id="tbl"
                        style={{
                          textAlign: "center",
                          fontWeight: "bold",
                          whiteSpace: "normal",
                          padding: "1px",
                          fontSize: "18px",
                        }}
                      >
                        <td
                          className="text-primary"
                          style={{
                            textAlign: "center",
                            fontWeight: "bold",
                            whiteSpace: "normal",
                            padding: "1px",
                            fontSize: "18px",
                          }}
                        >
                          Grand Total
                        </td>
                        <td
                          className="text-primary"
                          style={{
                            textAlign: "center",
                            fontWeight: "bold",
                            whiteSpace: "normal",
                            padding: "1px",
                            fontSize: "18px",
                          }}
                        >
                          {getCampusMale(reportData)}
                        </td>
                        <td
                          className="text-primary"
                          style={{
                            textAlign: "center",
                            fontWeight: "bold",
                            whiteSpace: "normal",
                            padding: "1px",
                            fontSize: "18px",
                          }}
                        >
                          {getCampusFemale(reportData)}
                        </td>
                        <td
                          className="text-primary"
                          style={{
                            textAlign: "center",
                            fontWeight: "bold",
                            whiteSpace: "normal",
                            padding: "1px",
                            fontSize: "18px",
                          }}
                        >
                          {getCampusTotal(reportData)}
                        </td>
                      </tr>
                    </>
                  </tbody>
                </Table>
              </div>
            ) : null}
            {/* campus class wise data is here */}
            {showCampusClass ? (
              <div id="tableboot">
                <Table
                  style={{
                    whiteSpace: "normal",
                    padding: "1px",
                    fontSize: "18px",
                  }}
                  responsive
                >
                  <thead
                    style={{
                      whiteSpace: "normal",
                      padding: "1px",
                      fontSize: "18px",
                    }}
                  >
                    <tr
                      id="tbl"
                      style={{
                        whiteSpace: "normal",
                        padding: "1px",
                        fontSize: "18px",
                      }}
                    >
                      <th
                        className="text-center"
                        style={{
                          whiteSpace: "normal",
                          padding: "1px",
                          fontSize: "18px",
                        }}
                      >
                        Class Name
                      </th>
                      <th
                        className="text-center"
                        style={{
                          whiteSpace: "normal",
                          padding: "1px",
                          fontSize: "18px",
                        }}
                      >
                        Male
                      </th>
                      <th
                        className="text-center"
                        style={{
                          whiteSpace: "normal",
                          padding: "1px",
                          fontSize: "18px",
                        }}
                      >
                        Female
                      </th>
                      <th
                        className="text-center"
                        style={{
                          whiteSpace: "normal",
                          padding: "1px",
                          fontSize: "18px",
                        }}
                      >
                        Total Admissions
                      </th>
                    </tr>
                  </thead>

                  <tbody
                    style={{
                      whiteSpace: "normal",
                      padding: "1px",
                      fontSize: "18px",
                    }}
                  >
                    {reportData &&
                      reportData?.map((campus) => (
                        <>
                          {getCampusClassTotal(campus) > 0 && (
                            <div>
                              <h4
                                className="text-primary mt-3"
                                style={{
                                  whiteSpace: "normal",
                                  padding: "1px",
                                  fontSize: "18px",
                                }}
                              >
                                {campus.Campus}
                              </h4>
                            </div>
                          )}
                          {campus.Classes.map((item, index) =>
                            item.report[0].total_admissions == "0" ? (
                              ""
                            ) : (
                              <>
                                <tr id="tbl">
                                  <td
                                    style={{
                                      color: "black",
                                      textAlign: "center",
                                      whiteSpace: "normal",
                                      padding: "1px",
                                      fontSize: "18px",
                                    }}
                                  >
                                    {item.Class.name}
                                  </td>
                                  <td
                                    style={{
                                      color: "black",
                                      textAlign: "center",
                                      whiteSpace: "normal",
                                      padding: "1px",
                                      fontSize: "18px",
                                    }}
                                  >
                                    {item.report[0].male}
                                  </td>
                                  <td
                                    style={{
                                      color: "black",
                                      textAlign: "center",
                                      whiteSpace: "normal",
                                      padding: "1px",
                                      fontSize: "18px",
                                    }}
                                  >
                                    {item.report[0].female}
                                  </td>
                                  <td
                                    style={{
                                      color: "black",
                                      textAlign: "center",
                                      whiteSpace: "normal",
                                      padding: "1px",
                                      fontSize: "18px",
                                    }}
                                  >
                                    {item.report[0].total_admissions}
                                  </td>
                                </tr>
                              </>
                            )
                          )}
                          {getCampusClassTotal(campus) > 0 && (
                            <tr id="tbl">
                              <td
                                className="text-primary"
                                style={{
                                  textAlign: "center",
                                  whiteSpace: "normal",
                                  padding: "1px",
                                  fontSize: "18px",
                                }}
                              >
                                Sub Total
                              </td>
                              <td
                                className="text-primary"
                                style={{
                                  textAlign: "center",
                                  whiteSpace: "normal",
                                  padding: "1px",
                                  fontSize: "18px",
                                }}
                              >
                                {campus.Classes.reduce(
                                  (a, c) => a + parseInt(c.report[0].male),
                                  0
                                )}
                              </td>
                              <td
                                className="text-primary"
                                style={{
                                  textAlign: "center",
                                  whiteSpace: "normal",
                                  padding: "1px",
                                  fontSize: "18px",
                                }}
                              >
                                {campus.Classes.reduce(
                                  (a, c) => a + parseInt(c.report[0].female),
                                  0
                                )}
                              </td>
                              <td
                                className="text-primary"
                                style={{
                                  textAlign: "center",
                                  whiteSpace: "normal",
                                  padding: "1px",
                                  fontSize: "18px",
                                }}
                              >
                                {campus.Classes.reduce(
                                  (a, c) =>
                                    a + parseInt(c.report[0].total_admissions),
                                  0
                                )}
                              </td>
                            </tr>
                          )}
                        </>
                      ))}
                    <tr
                      id="tbl"
                      style={{
                        borderTop: "1px solid",
                        textAlign: "center",
                        whiteSpace: "normal",
                        padding: "1px",
                        fontSize: "18px",
                      }}
                    >
                      <td
                        className="text-primary"
                        style={{
                          textAlign: "center",
                          whiteSpace: "normal",
                          padding: "1px",
                          fontSize: "18px",
                          fontWeight: "bold",
                        }}
                      >
                        Grand Total
                      </td>
                      <td
                        className="text-primary"
                        style={{
                          textAlign: "center",
                          whiteSpace: "normal",
                          padding: "1px",
                          fontSize: "18px",
                          fontWeight: "bold",
                        }}
                      >
                        {maleGrand}
                      </td>
                      <td
                        className="text-primary"
                        style={{
                          textAlign: "center",
                          whiteSpace: "normal",
                          padding: "1px",
                          fontSize: "18px",
                          fontWeight: "bold",
                        }}
                      >
                        {femaleGrand}
                      </td>
                      <td
                        className="text-primary"
                        style={{
                          textAlign: "center",
                          whiteSpace: "normal",
                          padding: "1px",
                          fontSize: "18px",
                          fontWeight: "bold",
                        }}
                      >
                        {lastTotal}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            ) : null}
            {/*  class wise data is here */}
            {showClass ? (
              <div id="tableboot">
                <Table
                  style={{
                    textAlign: "center",
                    whiteSpace: "normal",
                    padding: "1px",
                    fontSize: "18px",
                  }}
                  responsive
                >
                  <thead
                    style={{
                      textAlign: "center",
                      whiteSpace: "normal",
                      padding: "1px",
                      fontSize: "18px",
                    }}
                  >
                    <tr
                      id="tbl"
                      style={{
                        textAlign: "center",
                        whiteSpace: "normal",
                        padding: "1px",
                        fontSize: "18px",
                      }}
                    >
                      <th
                        className="text-center"
                        style={{
                          textAlign: "center",
                          whiteSpace: "normal",
                          padding: "1px",
                          fontSize: "18px",
                        }}
                      >
                        Class Name
                      </th>
                      <th
                        className="text-center"
                        style={{
                          textAlign: "center",
                          whiteSpace: "normal",
                          padding: "1px",
                          fontSize: "18px",
                        }}
                      >
                        Total Admissions
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {reportData &&
                      reportData?.map((month) => (
                        <>
                          <div>
                            <h4
                              className="text-primary mt-3"
                              style={{
                                textAlign: "center",
                                whiteSpace: "normal",
                                padding: "1px",
                                fontSize: "18px",
                              }}
                            >
                              {month.month_year}
                            </h4>
                          </div>
                          {month.Classes.map((item, index) =>
                            item.Class != null && item.total_admissions != 0 ? (
                              <>
                                <tr id="tbl">
                                  <td
                                    style={{
                                      color: "black",
                                      whiteSpace: "normal",
                                      padding: "1px",
                                      fontSize: "18px",
                                    }}
                                  >
                                    {item.Class.name}
                                  </td>
                                  <td
                                    style={{
                                      color: "black",
                                      whiteSpace: "normal",
                                      padding: "1px",
                                      fontSize: "18px",
                                    }}
                                  >
                                    {item.total_admissions}
                                  </td>
                                </tr>
                              </>
                            ) : (
                              ""
                            )
                          )}
                          {month?.Classes?.total_admissions != 0 ? (
                            <div
                              style={{
                                width: "146%",
                                textAlign: "end",
                                whiteSpace: "normal",
                                padding: "1px",
                                fontSize: "18px",
                              }}
                            >
                              <h5
                                style={{
                                  textAlign: "center",
                                  whiteSpace: "normal",
                                  padding: "1px",
                                  fontSize: "18px",
                                }}
                              >
                                Sub Total :{" "}
                                {month.Classes.reduce(
                                  (a, c) => a + parseInt(c.total_admissions),
                                  0
                                )}
                              </h5>
                            </div>
                          ) : null}
                        </>
                      ))}
                  </tbody>
                </Table>
                <div
                  style={{
                    width: "78%",
                    textAlign: "end",
                  }}
                >
                  <h4
                    className="text-primary"
                    style={{
                      textAlign: "center",
                      whiteSpace: "normal",
                      padding: "1px",
                      fontSize: "18px",
                    }}
                  >
                    Grand Total:{grandTotal}
                  </h4>
                </div>
              </div>
            ) : null}
            {/* monthy fee wise ................... */}
            {showMonthly ? (
              <div id="tableboot">
                <Table
                  style={{
                    textAlign: "center",
                    whiteSpace: "normal",
                    padding: "1px",
                    fontSize: "18px",
                  }}
                  responsive
                >
                  <thead
                    style={{
                      textAlign: "center",
                      whiteSpace: "normal",
                      padding: "1px",
                      fontSize: "18px",
                    }}
                  >
                    <tr
                      style={{
                        textAlign: "center",
                        whiteSpace: "normal",
                        padding: "1px",
                        fontSize: "18px",
                      }}
                      id="tbl"
                    >
                      <th
                        style={{
                          textAlign: "center",
                          whiteSpace: "normal",
                          padding: "1px",
                          fontSize: "18px",
                        }}
                        className="text-center"
                      >
                        Monthly Fee
                      </th>
                      <th
                        style={{
                          textAlign: "center",
                          whiteSpace: "normal",
                          padding: "1px",
                          fontSize: "18px",
                        }}
                        className="text-center"
                      >
                        No of Students
                      </th>
                      <th
                        style={{
                          textAlign: "center",
                          whiteSpace: "normal",
                          padding: "1px",
                          fontSize: "18px",
                        }}
                        className="text-center"
                      >
                        Amount
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {reportData &&
                      reportData?.map((month) => (
                        <>
                          <div>
                            <h4
                              style={{
                                textAlign: "center",
                                whiteSpace: "normal",
                                padding: "1px",
                                fontSize: "18px",
                              }}
                              className="text-primary mt-3"
                            >
                              {month.month_year}
                            </h4>
                          </div>
                          {month.fees.map((item, index) => (
                            <>
                              <tr id="tbl">
                                <td
                                  style={{
                                    color: "black",
                                    textAlign: "center",
                                    whiteSpace: "normal",
                                    padding: "1px",
                                    fontSize: "18px",
                                  }}
                                >
                                  {item.amount}
                                </td>
                                <td
                                  style={{
                                    color: "black",
                                    textAlign: "center",
                                    whiteSpace: "normal",
                                    padding: "1px",
                                    fontSize: "18px",
                                  }}
                                >
                                  {item.count}
                                </td>
                                <td
                                  style={{
                                    color: "black",
                                    textAlign: "center",
                                    whiteSpace: "normal",
                                    padding: "1px",
                                    fontSize: "18px",
                                  }}
                                >
                                  {Number(item.count) * Number(item.amount)}
                                </td>
                              </tr>
                            </>
                          ))}
                          <tr id="tbl">
                            <td
                              className="text-primary"
                              style={{
                                textAlign: "center",
                                textAlign: "center",
                                whiteSpace: "normal",
                                padding: "1px",
                                fontSize: "18px",
                              }}
                            >
                              Sub Total
                            </td>
                            <td
                              className="text-primary"
                              style={{
                                textAlign: "center",
                                whiteSpace: "normal",
                                padding: "1px",
                                fontSize: "18px",
                              }}
                            >
                              {month.fees.reduce(
                                (a, c) => a + parseInt(c.count),
                                0
                              )}
                            </td>
                            <td
                              className="text-primary"
                              style={{
                                textAlign: "center",
                                whiteSpace: "normal",
                                padding: "1px",
                                fontSize: "18px",
                              }}
                            >
                              {month.fees.reduce(
                                (a, c) =>
                                  a + Number(c.count) * Number(c.amount),
                                0
                              )}
                            </td>
                          </tr>
                        </>
                      ))}
                    <tr id="tbl">
                      <td
                        className="text-primary"
                        style={{
                          textAlign: "center",
                          fontWeight: "bold",
                          whiteSpace: "normal",
                          padding: "1px",
                          fontSize: "18px",
                        }}
                      >
                        Grand Total
                      </td>
                      <td
                        className="text-primary"
                        style={{
                          fontWeight: "bold",
                          textAlign: "center",
                          whiteSpace: "normal",
                          padding: "1px",
                          fontSize: "18px",
                        }}
                      >
                        {contGrand}
                      </td>
                      <td
                        className="text-primary"
                        style={{
                          fontWeight: "bold",
                          textAlign: "center",
                          whiteSpace: "normal",
                          padding: "1px",
                          fontSize: "18px",
                        }}
                      >
                        {amountGrand}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            ) : null}
            {/* date wise ................... */}
            {showDate ? (
              <div id="tableboot">
                <Table
                  responsive
                  style={{
                    whiteSpace: "normal",
                    padding: "1px",
                    fontSize: "18px",
                  }}
                >
                  <thead
                    style={{
                      whiteSpace: "normal",
                      padding: "1px",
                      fontSize: "18px",
                    }}
                  >
                    <tr
                      id="tbl"
                      style={{
                        whiteSpace: "normal",
                        padding: "1px",
                        fontSize: "18px",
                      }}
                    >
                      <th
                        className="text-center"
                        style={{
                          whiteSpace: "normal",
                          padding: "1px",
                          fontSize: "18px",
                        }}
                      >
                        Date
                      </th>
                      <th
                        className="text-center"
                        style={{
                          whiteSpace: "normal",
                          padding: "1px",
                          fontSize: "18px",
                        }}
                      >
                        Male
                      </th>
                      <th
                        className="text-center"
                        style={{
                          whiteSpace: "normal",
                          padding: "1px",
                          fontSize: "18px",
                        }}
                      >
                        Female
                      </th>
                      <th
                        className="text-center"
                        style={{
                          whiteSpace: "normal",
                          padding: "1px",
                          fontSize: "18px",
                        }}
                      >
                        Total Admissions
                      </th>
                    </tr>
                  </thead>

                  <tbody
                    style={{
                      whiteSpace: "normal",
                      padding: "1px",
                      fontSize: "18px",
                    }}
                  >
                    {reportData && (
                      <>
                        {reportData.map((item, index) => (
                          <tr
                            id="tbl"
                            style={{
                              whiteSpace: "normal",
                              padding: "1px",
                              fontSize: "18px",
                            }}
                          >
                            <td
                              style={{
                                color: "black",
                                textAlign: "center",

                                whiteSpace: "normal",
                                padding: "1px",
                                fontSize: "18px",
                              }}
                            >
                              {item.date}
                            </td>
                            <td
                              style={{
                                color: "black",
                                textAlign: "center",
                                whiteSpace: "normal",
                                padding: "1px",
                                fontSize: "18px",
                              }}
                            >
                              {item.male}
                            </td>
                            <td
                              style={{
                                color: "black",
                                textAlign: "center",
                                whiteSpace: "normal",
                                padding: "1px",
                                fontSize: "18px",
                              }}
                            >
                              {item.female}
                            </td>
                            <td
                              style={{
                                color: "black",
                                textAlign: "center",
                                whiteSpace: "normal",
                                padding: "1px",
                                fontSize: "18px",
                              }}
                            >
                              {item.total_admissions}
                            </td>
                          </tr>
                        ))}
                        <tr id="tbl">
                          <td
                            className="text-primary"
                            style={{
                              textAlign: "center",
                              fontWeight: "bold",
                              whiteSpace: "normal",
                              padding: "1px",
                              fontSize: "18px",
                            }}
                          >
                            Grand Total
                          </td>
                          <td
                            className="text-primary"
                            style={{
                              textAlign: "center",
                              fontWeight: "bold",
                              whiteSpace: "normal",
                              padding: "1px",
                              fontSize: "18px",
                            }}
                          >
                            {reportData.reduce(
                              (a, c) => a + parseInt(c.male),
                              0
                            )}
                          </td>
                          <td
                            className="text-primary"
                            style={{
                              textAlign: "center",
                              fontWeight: "bold",
                              whiteSpace: "normal",
                              padding: "1px",
                              fontSize: "18px",
                            }}
                          >
                            {reportData.reduce(
                              (a, c) => a + parseInt(c.female),
                              0
                            )}
                          </td>
                          <td
                            className="text-primary"
                            style={{
                              textAlign: "center",
                              fontWeight: "bold",
                              whiteSpace: "normal",
                              padding: "1px",
                              fontSize: "18px",
                            }}
                          >
                            {reportData.reduce(
                              (a, c) => a + parseInt(c.male),
                              0
                            ) +
                              reportData.reduce(
                                (a, c) => a + parseInt(c.female),
                                0
                              )}
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </Table>
              </div>
            ) : null}
            {/* Inactive wise ................... */}
            {showInactiveWise ? (
              <div id="tableboot">
                <Table
                  responsive
                  style={{
                    textAlign: "center",
                    whiteSpace: "normal",
                    padding: "1px",
                    fontSize: "18px",
                  }}
                >
                  <thead
                    style={{
                      textAlign: "center",
                      whiteSpace: "normal",
                      padding: "1px",
                      fontSize: "18px",
                    }}
                  >
                    <tr
                      id="tbl"
                      style={{
                        textAlign: "center",
                        whiteSpace: "normal",
                        padding: "1px",
                        fontSize: "18px",
                      }}
                    >
                      {/* <th className="text-center">Month & Year</th> */}
                      <th
                        style={{
                          textAlign: "center",
                          whiteSpace: "normal",
                          padding: "1px",
                          fontSize: "18px",
                        }}
                        className="text-center"
                      >
                        Male
                      </th>
                      <th
                        style={{
                          textAlign: "center",
                          whiteSpace: "normal",
                          padding: "1px",
                          fontSize: "18px",
                        }}
                        className="text-center"
                      >
                        Female
                      </th>
                      <th
                        style={{
                          textAlign: "center",
                          whiteSpace: "normal",
                          padding: "1px",
                          fontSize: "18px",
                        }}
                        className="text-center"
                      >
                        Total Admissions
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {/* {reportData &&
                  reportData.map((item, index) => ( */}
                    <tr
                      id="tbl"
                      style={{
                        textAlign: "center",
                        whiteSpace: "normal",
                        padding: "1px",
                        fontSize: "18px",
                      }}
                    >
                      {/* <td style={{ color: "black", textAlign: "center" }}>
                        {item.month_year}
                      </td> */}
                      <td
                        style={{
                          textAlign: "center",
                          whiteSpace: "normal",
                          padding: "1px",
                          fontSize: "18px",
                        }}
                      >
                        {reportData.male}
                      </td>
                      <td
                        style={{
                          color: "black",
                          textAlign: "center",
                          whiteSpace: "normal",
                          padding: "1px",
                          fontSize: "18px",
                        }}
                      >
                        {reportData.female}
                      </td>
                      <td
                        style={{
                          color: "black",
                          textAlign: "center",
                          whiteSpace: "normal",
                          padding: "1px",
                          fontSize: "18px",
                        }}
                      >
                        {reportData.total_admissions}
                      </td>
                    </tr>
                    {/* ))} */}
                    {/* <tr id="tbl">
                                <td
                                className="text-primary"
                                style={{
                                  textAlign: "center",
                                  fontSize: "1.2rem",
                                  fontWeight: "bold",
                                }}
                                >
                                 Grand Total
                                </td>
                                <td
                                  className="text-primary"
                                  style={{
                                    textAlign: "center",
                                    fontSize: "1.2rem",
                                    fontWeight: "bold",
                                  }}
                                >
                                 { reportData.reduce((a, c) => a +parseInt(c.male), 0)}
                                </td>
                                <td
                                  className="text-primary"
                                  style={{
                                    textAlign: "center",
                                    fontSize: "1.2rem",
                                    fontWeight: "bold",
                                  }}
                                >
                                 { reportData.reduce((a, c) => a +parseInt(c.female), 0)}
                                 
                                </td>
                                <td
                                 className="text-primary"
                                 style={{
                                   textAlign: "center",
                                   fontSize: "1.2rem",
                                   fontWeight: "bold",
                                 }}
                                >
                                  { reportData.reduce((a, c) => a +parseInt(c.male), 0)+reportData.reduce((a, c) => a +parseInt(c.female), 0)}
                                </td>
                              </tr> */}
                  </tbody>
                </Table>
              </div>
            ) : null}
          </div>
        </div>
      </Paper>
    </>
  );
};

export default StudentTotalAdm;
