import React, { useEffect, useState } from "react";
import { Table, Modal } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { baseUrl } from "../../../../services/config";
import Pagination from "@material-ui/lab/Pagination";
import EditStudent from "../../ShowAllStudents/EditStudent/EditStudent";
import { Paper } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import usePagination from "../../../../components/usePagination/usePagination";
import { useAlert } from "react-alert";
import { PremissionChecker } from "../../../../services/PremissionChecker";

const SearchStudent = () => {
  const authtoken = localStorage.getItem("userToken");
  const user = useSelector((state) => state.user.user);
  const alert = useAlert();
  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage, setpostPerPage] = useState(10);
  const [editModal, seteditModal] = useState(false);
  const [searchtext, setsearchtext] = useState("");
  const [loading, setloading] = useState(false);
  const [AllStudent, setAllStudent] = useState([]);
  const [selectedStudent, setselectedStudent] = useState({});
  const [allcampuses, setallcampuses] = useState([]);
  const [campusID, setcampusID] = useState("");
  const [error, setError] = useState("");
  const [allconcessions, setallconcessions] = useState([]);
  const [allhostel, setallHostel] = useState([]);
  const [allVehical, setallVehical] = useState([]);
  const [defaulthol, setDefaulthol] = useState({});
  const [defaultCon, setDefaultCon] = useState({});
  const [defaultVel, setDefaultVel] = useState({});
  const dispatch = useDispatch();
  let campusidget = useSelector((state) => state.campuses.setselectedcampusid);
  const [previousselectedcampus, setpreviousselectedcampus] = useState(
    campusidget
  );

  // Search Student States
  const [selectedSection, setselectedsection] = useState("");
  const [studentName, setStudentName] = useState(true);
  const [studentFather, setStudentFather] = useState(false);
  const [studentIdCard, setStudentIdCard] = useState(false);
  const [studentAddress, setStudentAddress] = useState(false);
  const [studentMobile, setStudentMobile] = useState(false);
  const [studentAdmNo, setStudentAdmNo] = useState(false);

  const [selected, setSelected] = useState("studentName");

  useEffect(() => {
    if (campusidget != "") {
      setpreviousselectedcampus(campusidget);
      getAllStudents("", campusidget);
    }
    // getAllCampus();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const fdata = new FormData();

    fdata.append("student_name", studentName ? 1 : 0);
    fdata.append("student_father_name", studentFather ? 1 : 0);
    fdata.append("student_ID_card", studentIdCard ? 1 : 0);
    fdata.append("student_addess", studentAddress ? 1 : 0);
    fdata.append("student_mobile_no", studentMobile ? 1 : 0);
    fdata.append("student_admission_no", studentAdmNo ? 1 : 0);
  };

  const onRadioButtonClick = (e) => {
    const value = e.target.value;

    console.log(value);
    setSelected(e.target.value);
    if (value == "studentName") {
      setStudentName(true);
      setStudentFather(false);
      setStudentIdCard(false);
      setStudentAddress(false);
      setStudentMobile(false);
      setStudentAdmNo(false);
    }
    if (value == "studentFather") {
      setStudentName(false);
      setStudentFather(true);
      setStudentIdCard(false);
      setStudentAddress(false);
      setStudentMobile(false);
      setStudentAdmNo(false);
    }
    if (value == "studentIdCard") {
      setStudentName(false);
      setStudentFather(false);
      setStudentIdCard(true);
      setStudentAddress(false);
      setStudentMobile(false);
      setStudentAdmNo(false);
    }
    if (value == "studentAddress") {
      setStudentName(false);
      setStudentFather(false);
      setStudentIdCard(false);
      setStudentAddress(true);
      setStudentMobile(false);
      setStudentAdmNo(false);
    }
    if (value == "studentMobile") {
      setStudentName(false);
      setStudentFather(false);
      setStudentIdCard(false);
      setStudentAddress(false);
      setStudentMobile(true);
      setStudentAdmNo(false);
    }
    if (value == "studentAdmNo") {
      setStudentName(false);
      setStudentFather(false);
      setStudentIdCard(false);
      setStudentAddress(false);
      setStudentMobile(false);
      setStudentAdmNo(true);
    }
  };

  const onSectionChange = (id) => {
    setselectedsection(id);
  };

  const getAllStudents = async (e, value) => {
    console.log(e, "e");
    console.log(value, "value");
    let id;
    if (e) {
      id = e.target.value;
      setcampusID(id);
      console.log(id);
      // setloading(true);
    } else {
      id = value;
    }
    dispatch({
      type: "SELECTED_CAMPUS_ID",
      payload: {
        setselectedcampusid: id,
      },
    });
    setpreviousselectedcampus(id);
  };

  const openEditDialog = (student) => {
    setDefaultCon({});
    setDefaulthol({});
    setDefaultVel({});
    console.log(student);
    setselectedStudent(student);
    console.log(student.concession_id, "yabbolellellel");
    if (student.concession_id != null) {
      const defaultValue = allconcessions.find(
        (item) => item.id == student.concession_id
      );
      setDefaultCon(defaultValue);
    }
    if (student.hostel_id != null) {
      const defaultValue = allhostel.find(
        (item) => item.id == student.hostel_id
      );
      setDefaulthol(defaultValue);
    }
    if (student.vehicle_id != null) {
      const defaultValue = allVehical.find(
        (item) => item.id == student.vehicle_id
      );
      setDefaultVel(defaultValue);
    }
    seteditModal(true);
  };
  const indexOfLastPost = currentPage * postPerPage;

  const indexOfFirstPost = indexOfLastPost - postPerPage;

  const dataSearch = AllStudent.filter((user) => {
    let text = searchtext.toLowerCase();
    return searchtext
      ? user.name?.toLowerCase().includes(text) ||
          user?.admission_id?.toLowerCase().includes(text) ||
          user?.father_name?.toLowerCase().includes(text) ||
          user?.father_cnic?.toLowerCase().includes(text) ||
          user?.address?.toLowerCase().includes(text) ||
          user?.mobile_no?.toLowerCase().includes(text)
      : true;
  });

  let [page, setPage] = useState(1);
  const PER_PAGE = 10;

  const count = Math.ceil(dataSearch.length / PER_PAGE);
  const _DATA = usePagination(dataSearch, PER_PAGE);

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  const searchByCode = (e) => {
    e.preventDefault();
    if (searchtext == "") {
      alert.error("Please Enter Admission Id");
    } else {
      const data = {
        search_keyword: searchtext.toLocaleUpperCase(),
      };
      setloading(true);

      fetch(`${baseUrl}/search_by_adm_id`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authtoken}`,
        },

        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          console.log(data);
          if (data.metadata.success) {
            if (data.payload.length === 0) {
              alert.error("No Record Found!!");
              setloading(false);
            }
            const arr = data.payload;
            arr.forEach((item, i) => {
              item["Sr"] = i + 1;
            });
            setAllStudent(arr);
            // setAllStaff(arr);
            setloading(false);
          } else if (!data.metadata.success) {
            Object.values(data.metadata.message).map((msg) => {
              alert.error(msg[0]);
              setloading(false);
            });
          }
        })
        .catch((err) => {
          console.log("err is re", err);
          setError("No Record Found!!");
          console.log(error);
        });
    }
  };

  const searchByName = (e) => {
    e.preventDefault();
    if (searchtext == "") {
      alert.error("Please Enter Student's Name");
    } else {
      const data = {
        search_keyword: searchtext.toLocaleUpperCase(),
      };
      setloading(true);

      fetch(`${baseUrl}/search_by_name`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authtoken}`,
        },

        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          if (data.metadata.success) {
            if (data.payload.length === 0) {
              alert.error("No Record Found!!");
              setloading(false);
            }
            const arr = data.payload;
            arr.forEach((item, i) => {
              item["Sr"] = i + 1;
            });
            setAllStudent(arr);
            // setAllStaff(arr);
            setloading(false);
          } else if (!data.metadata.success) {
            Object.values(data.metadata.message).map((msg) => {
              alert.error(msg[0]);
              setloading(false);
            });
          }
        })
        .catch((err) => {
          console.log("err is re", err);
        });
    }
  };

  const searchByFather = (e) => {
    e.preventDefault();
    if (searchtext == "") {
      alert.error("Please Enter Father's Name");
    } else {
      const data = {
        search_keyword: searchtext.toLocaleUpperCase(),
      };
      setloading(true);

      fetch(`${baseUrl}/search_by_father_name`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authtoken}`,
        },

        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          if (data.metadata.success) {
            if (data.payload.length === 0) {
              alert.error("No Record Found!!");
              setloading(false);
            }
            const arr = data.payload;
            arr.forEach((item, i) => {
              item["Sr"] = i + 1;
            });
            setAllStudent(arr);
            // setAllStaff(arr);
            setloading(false);
          } else if (!data.metadata.success) {
            Object.values(data.metadata.message).map((msg) => {
              alert.error(msg[0]);
              setloading(false);
            });
          }
        })
        .catch((err) => {
          console.log("err is re", err);
        });
    }
  };

  const searchByID = (e) => {
    e.preventDefault();
    if (searchtext == "") {
      alert.error("Please Enter Father's ID Card");
    } else {
      const data = {
        search_keyword: searchtext.toLocaleUpperCase(),
      };
      setloading(true);

      fetch(`${baseUrl}/search_by_id_card`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authtoken}`,
        },

        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          if (data.metadata.success) {
            if (data.payload.length === 0) {
              alert.error("No Record Found!!");
              setloading(false);
            }
            const arr = data.payload;
            arr.forEach((item, i) => {
              item["Sr"] = i + 1;
            });
            setAllStudent(arr);
            // setAllStaff(arr);
            setloading(false);
          } else if (!data.metadata.success) {
            Object.values(data.metadata.message).map((msg) => {
              alert.error(msg[0]);
              setloading(false);
            });
          }
        })
        .catch((err) => {
          console.log("err is re", err);
        });
    }
  };

  const searchByAddress = (e) => {
    e.preventDefault();
    if (searchtext == "") {
      alert.error("Please Enter Student's Address");
    } else {
      const data = {
        search_keyword: searchtext.toLocaleUpperCase(),
      };
      setloading(true);

      fetch(`${baseUrl}/search_by_address`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authtoken}`,
        },

        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          if (data.metadata.success) {
            if (data.payload.length === 0) {
              alert.error("No Record Found!!");
              setloading(false);
            }
            const arr = data.payload;
            arr.forEach((item, i) => {
              item["Sr"] = i + 1;
            });
            setAllStudent(arr);
            // setAllStaff(arr);
            setloading(false);
          } else if (!data.metadata.success) {
            Object.values(data.metadata.message).map((msg) => {
              alert.error(msg[0]);
              setloading(false);
            });
          }
        })
        .catch((err) => {
          console.log("err is re", err);
        });
    }
  };

  const searchByMobile = (e) => {
    e.preventDefault();
    if (searchtext == "") {
      alert.error("Please Enter Student's Mobile No.");
    } else {
      const data = {
        search_keyword: searchtext.toLocaleUpperCase(),
      };
      setloading(true);

      fetch(`${baseUrl}/search_by_mobile_no`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authtoken}`,
        },

        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          if (data.metadata.success) {
            if (data.payload.length === 0) {
              alert.error("No Record Found!!");
              setloading(false);
            }
            const arr = data.payload;
            arr.forEach((item, i) => {
              item["Sr"] = i + 1;
            });
            setAllStudent(arr);
            // setAllStaff(arr);
            setloading(false);
          } else if (!data.metadata.success) {
            Object.values(data.metadata.message).map((msg) => {
              alert.error(msg[0]);
              setloading(false);
            });
          }
        })
        .catch((err) => {
          console.log("err is re", err);
        });
    }
  };

  // const loginAccount = JSON.parse(localStorage.getItem("userDetails")).role[0];

  return (
    <div>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        {/* <h1>Search Student</h1> */}
        <form onSubmit={handleSubmit}>
          <div className="row mt-4">
            <div className="col-sm-3 mt-2">
              <input
                checked={selected == "studentName"}
                onChange={(e) => onRadioButtonClick(e)}
                defaultChecked
                type="radio"
                id="StudentName"
                name="contact"
                value="studentName"
              />
              <label for="StudentName">Search by Name</label>
            </div>

            <div className="col-sm-3 mt-2">
              <input
                checked={selected == "studentFather"}
                onChange={(e) => onRadioButtonClick(e)}
                type="radio"
                id="StudentFather"
                name="contact"
                value="studentFather"
              />
              <label for="StudentFather">Search by Father Name</label>
            </div>

            <div className="col-sm-3 mt-2">
              <input
                checked={selected == "studentIdCard"}
                onChange={(e) => onRadioButtonClick(e)}
                type="radio"
                id="StudentIdCard"
                name="contact"
                value="studentIdCard"
              />
              <label for="StudentIdCard">Search by Father's ID Card</label>
            </div>

            <div className="col-sm-3 mt-2">
              <input
                checked={selected == "studentAddress"}
                onChange={(e) => onRadioButtonClick(e)}
                type="radio"
                id="StudentAddress"
                name="contact"
                value="studentAddress"
              />
              <label for="StudentAddress">Search by Address</label>
            </div>

            <div className="col-sm-3 mt-2">
              <input
                checked={selected == "studentMobile"}
                onChange={(e) => onRadioButtonClick(e)}
                type="radio"
                id="StudentMobile"
                name="contact"
                value="studentMobile"
              />
              <label for="StudentMobile">Search by Mobile No.</label>
            </div>

            <div className="col-sm-3 mt-2">
              <input
                checked={selected == "studentAdmNo"}
                onChange={(e) => onRadioButtonClick(e)}
                type="radio"
                id="StudentAdmNo"
                name="contact"
                value="studentAdmNo"
              />
              <label for="StudentAdmNo">Search by Admission No.</label>
            </div>
          </div>

          <div className="d-flex" style={{ flexWrap: "wrap" }}>
            {studentName ? (
              <form onSubmit={searchByName} style={{ width: "100%" }}>
                <div className="row mt-6">
                  <div className="col-md-5">
                    <input
                      onChange={(e) => setsearchtext(e.target.value)}
                      className="form-control"
                      placeholder="Enter Student Name"
                    />
                  </div>
                  <div className="col-md-4">
                    <button type="submit" className="btn btn-primary">
                      Search
                    </button>
                  </div>
                </div>
              </form>
            ) : null}

            {studentFather ? (
              <form onSubmit={searchByFather} style={{ width: "100%" }}>
                <div className="row mt-6">
                  <div className="col-md-5">
                    <input
                      onChange={(e) => setsearchtext(e.target.value)}
                      className="form-control"
                      placeholder="Enter Student's Father Name"
                    />
                  </div>
                  <div className="col-md-4">
                    <button type="submit" className="btn btn-primary">
                      Search
                    </button>
                  </div>
                </div>
              </form>
            ) : null}

            {studentIdCard ? (
              <form onSubmit={searchByID} style={{ width: "100%" }}>
                <div className="row mt-6">
                  <div className="col-md-5">
                    <input
                      onChange={(e) => setsearchtext(e.target.value)}
                      className="form-control"
                      placeholder="Enter Father's ID Card No. / CNIC No."
                    />
                  </div>
                  <div className="col-md-4">
                    <button type="submit" className="btn btn-primary">
                      Search
                    </button>
                  </div>
                </div>
              </form>
            ) : null}

            {studentAddress ? (
              <form onSubmit={searchByAddress} style={{ width: "100%" }}>
                <div className="row mt-6">
                  <div className="col-md-5">
                    <input
                      onChange={(e) => setsearchtext(e.target.value)}
                      className="form-control"
                      placeholder="Enter Student Address"
                    />
                  </div>
                  <div className="col-md-4">
                    <button type="submit" className="btn btn-primary">
                      Search
                    </button>
                  </div>
                </div>
              </form>
            ) : null}

            {studentMobile ? (
              <form onSubmit={searchByMobile} style={{ width: "100%" }}>
                <div className="row mt-6">
                  <div className="col-md-5">
                    <input
                      onChange={(e) => setsearchtext(e.target.value)}
                      className="form-control"
                      placeholder="Enter Father's Mobile No. / Phone No."
                    />
                  </div>
                  <div className="col-md-4">
                    <button type="submit" className="btn btn-primary">
                      Search
                    </button>
                  </div>
                </div>
              </form>
            ) : null}

            {studentAdmNo ? (
              <form onSubmit={searchByCode} style={{ width: "100%" }}>
                <div className="row mt-6">
                  <div className="col-md-5">
                    <input
                      onChange={(e) => setsearchtext(e.target.value)}
                      className="form-control"
                      placeholder="Enter Student Admission Number"
                    />
                  </div>
                  <div className="col-md-4">
                    <button type="submit" className="btn btn-primary">
                      Search
                    </button>
                  </div>
                </div>
              </form>
            ) : null}
          </div>
        </form>
        {/* <tr id="tbl" style={{position:"sticky",top:"0",zIndex:"2",backgroundColor:"gainsboro"}}> */}
        <div className="mt-4" id="tableboot">
          <div>
            {/* <div className="makeITScrollAAble"> */}
            <Table
              style={{
                width: "150%",
                marginTop: "10px",
                overflowY: "scroll",
              }}
              responsive
            >
              <thead>
                {/* <tr id="tbl" style={{ position: "relative" }}> */}
                {/* <Table responsive className="mt-5">
          <thead>*/}
                <tr className="text-center">
                  <th style={{ textAlign: "center" }}>Sr. No</th>
                  <th style={{ textAlign: "center" }}>Adm. ID</th>
                  <th style={{ textAlign: "start" }}>Name</th>
                  <th style={{ textAlign: "start" }}>Father's Name</th>
                  <th style={{ textAlign: "center" }}>Status</th>
                  <th style={{ textAlign: "center" }}>Class</th>
                  <th style={{ textAlign: "center" }}>Section</th>
                  <th style={{ textAlign: "center" }}>DOB</th>
                  <th style={{ textAlign: "center" }}>CNIC_No</th>
                  <th style={{ textAlign: "center" }}>Mobile No</th>
                  <th style={{ textAlign: "center" }}>Adm. Date</th>
                  <th style={{ textAlign: "center" }}>Session</th>
                  <th style={{ textAlign: "center" }}>Addess</th>
                  {PremissionChecker(user, "student.update") ? (
                    <th style={{ textAlign: "center" }}>Edit</th>
                  ) : null}
                </tr>
              </thead>

              <tbody>
                {_DATA.currentData().map((student, index) => (
                  // key={index}
                  <tr key={student?.id}>
                    <td>{student?.Sr}</td>
                    {/* <td className="text_align_start ">{student.id}</td> */}
                    <td className="text_align_center">
                      {student?.admission_id}
                    </td>
                    <td className="text_align_start">{student?.name}</td>

                    <td style={{ textAlign: "start" }}>
                      {student?.father_name}
                    </td>

                    {student?.status == "1" ? (
                      <td style={{ textAlign: "center", color: "orange" }}>
                        {"Registered Only"}
                      </td>
                    ) : student?.status == "2" ? (
                      <td style={{ textAlign: "center", color: "green" }}>
                        {"Admission Only"}
                      </td>
                    ) : student?.status == "3" ? (
                      <td style={{ textAlign: "center", color: "orange" }}>
                        {"Applied For Admission"}
                      </td>
                    ) : student?.status == "4" ? (
                      <td style={{ textAlign: "center", color: "red" }}>
                        {"Struck Off"}
                      </td>
                    ) : student?.status == "5" ? (
                      <td style={{ textAlign: "center", color: "red" }}>
                        {"Pass Out"}
                      </td>
                    ) : student?.status == "6" ? (
                      <td style={{ textAlign: "center", color: "red" }}>
                        {"Leaving"}
                      </td>
                    ) : student?.status == "7" ? (
                      <td style={{ textAlign: "center", color: "red" }}>
                        {"Migrate"}
                      </td>
                    ) : null}

                    <td style={{ textAlign: "center" }}>
                      {student?.student_class?.name}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {student?.global_section?.name}
                    </td>
                    <td style={{ textAlign: "center" }}>{student?.dob}</td>
                    <td style={{ textAlign: "center" }}>
                      {student?.father_cnic}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {"+" + student?.mobile_no}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {student?.Joining_date}
                    </td>

                    <td style={{ textAlign: "center" }}>
                      {student?.session?.year}
                    </td>
                    <td style={{ textAlign: "start" }}>{student?.address}</td>

                    {PremissionChecker(user, "student.update") ? (
                      <td
                        style={{ paddingLeft: "0px" }}
                        className="text-center"
                      >
                        <a
                          key={index}
                          className="btn btn-icon btn-light btn-hover-primary btn-sm"
                          onClick={(e) => {
                            openEditDialog(student);
                          }}
                        >
                          <span
                            key={index}
                            className="svg-icon svg-icon-md svg-icon-primary"
                          >
                            <SVG
                              key={index}
                              src={toAbsoluteUrl(
                                "/media/svg/icons/Communication/Write.svg"
                              )}
                            />
                          </span>
                        </a>
                      </td>
                    ) : null}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>

        <div className="row justify-content-center">
          {loading && <span className="spinner-border"></span>}
        </div>

        <Pagination
          color="primary"
          count={count}
          size="large"
          page={page}
          variant="text"
          shape="round"
          onChange={handleChange}
        />
        <Modal onHide={() => seteditModal(false)} size="xl" show={editModal}>
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              {selectedStudent.name} {selectedStudent.admission_id}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <EditStudent
              defConce={defaultCon}
              defaulthol={defaulthol}
              defaultVel={defaultVel}
              reloader={() => onSectionChange()}
              show={() => seteditModal()}
              reload={() => getAllStudents()}
              onUpdateStudent={getAllStudents}
              studentDetail={selectedStudent}
            />
          </Modal.Body>
        </Modal>
      </Paper>
    </div>
  );
};

export default SearchStudent;
