import React, { useState, useEffect } from "react";
import { baseUrl } from "../../../services/config";
// import { useSelector } from "react-redux";
import { useAlert } from "react-alert";
import SVG from "react-inlinesvg";
import { Table, Modal, Spinner } from "react-bootstrap";
import { Paper } from "@material-ui/core";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

const BranchesUrl = () => {
  const alert = useAlert();
  const authtoken = localStorage.getItem("userToken");
  const [branchUrlData, setBranchUrlData] = useState([]);
  // const user = useSelector((state) => state.user.user);
  const [loading, setloading] = useState(false);
  const [addModal, setaddModal] = useState(false);
  const [branchName, setBranchName] = useState("");
  const [BranchAddress, setBranchAddress] = useState("");
  const [backendUrl, setBackendUrl] = useState("");

  const [editBranchName, setEditBranchName] = useState("");
  const [editBranchAddress, setEditBranchAddress] = useState("");
  const [editBackendUrl, setEditBackendUrl] = useState("");
  const [selectedId, setselectedId] = useState("");
  const [addLoading, setaddLoading] = useState(false);
  const [deleteUrlId, setDeleteUrlId] = useState("");
  const [deleteModal, setdeleteModal] = useState(false);
  const [deleteLoading, setdeleteLoading] = useState(false);
  const [editModal, seteditModal] = useState(false);

  useEffect(() => {
    getBranchUrls();
  }, []);

  const getBranchUrls = async () => {
    setloading(true);
    fetch(`${baseUrl}/view-branch-url`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setBranchUrlData(data.payload);
        setloading(false);
      })
      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };

  const handleSubmit = () => {
    if (branchName === "") {
      alert.error("Enter Branch Name");
      return;
    }
    if (backendUrl === "") {
      alert.error("Enter Backend Url");
      return;
    }
    const data = {
      branch_name: branchName,
      branch_url: BranchAddress,
      backend_url: backendUrl,
    };
    setaddLoading(true);
    fetch(`${baseUrl}/add-new-branch-url`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) =>
        res.json().then((data) => {
          if (data.metadata.success) {
            setaddLoading(false);
            setaddModal(false);
            getBranchUrls();
            alert.success("Branch Added Successfully");
          } else {
            alert.error("Branches Not Added");
            setaddLoading(false);
            setaddModal(false);
          }
        })
      )
      .catch((err) => {
        console.log(err);
        setaddLoading(false);
        setaddModal(false);
      });
  };

  const handleUpdate = () => {
    if (editBranchName === "") {
      alert.error("Enter Branch Name");
      return;
    }
    if (editBackendUrl === "") {
      alert.error("Enter Backend Url");
      return;
    }
    const data = {
      id: selectedId,
      branch_name: editBranchName,
      branch_url: editBranchAddress,
      backend_url: editBackendUrl,
    };
    setaddLoading(true);
    fetch(`${baseUrl}/update-branch-url`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) =>
        res.json().then((data) => {
          if (data.metadata.success) {
            alert.success("Branch Updated Successfully");
            setaddLoading(false);
            seteditModal(false);
            getBranchUrls();
          } else {
            alert.error("Branches Not Updated");
            setaddLoading(false);
            setaddModal(false);
          }
        })
      )
      .catch((err) => {
        console.log(err);
        setaddLoading(false);
        setaddModal(false);
      });
  };

  const deleteBranchUrl = (id) => {
    setDeleteUrlId(id);
    setdeleteModal(true);
  };

  const DeleteBranchUrlHandler = () => {
    setdeleteLoading(true);
    setaddLoading(true);
    fetch(`${baseUrl}/delete-branch-url?id=${deleteUrlId}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata.success) {
          setdeleteModal(false);
          alert.success("Deleted Successfully");
          setdeleteLoading(false);
          getBranchUrls();
        }
      })
      .catch((err) => {
        setdeleteModal(false);
        setdeleteLoading(false);
        console.log(err);
      });
  };

  const middleUrlFunc = (url) => {
    window.open(url, "_blank");
  };

  const middleEditFunc = (branchData, index) => {
    setselectedId(index);
    setEditBranchName(branchData?.branches);
    setEditBranchAddress(branchData?.branch_url);
    setEditBackendUrl(branchData?.backend_url);
    seteditModal(true);
  };

  return (
    <div>
      <Paper
        style={{ marginTop: "15px", padding: "15px", marginBottom: "15px" }}
      >
        <button
          className="btn btn-success button_blue"
          onClick={() => setaddModal(true)}
        >
          Add Branch{" "}
        </button>
        <br />
        <br />
        <Table>
          <thead>
            <tr className="text-center">
              <th style={{ textAlign: "center" }}>Sr.#</th>
              <th style={{ textAlign: "start" }}>Branch Name</th>
              <th style={{ textAlign: "start" }}>Link</th>
              <th style={{ textAlign: "start" }}>Backend Url</th>
              <th style={{ textAlign: "center" }}>View</th>
              <th style={{ textAlign: "center" }}>Edit</th>
              <th style={{ textAlign: "center" }}>Delete</th>
            </tr>
          </thead>
          <tbody>
            {branchUrlData.map((item, index) => (
              <>
                <tr key={item.id}>
                  <td style={{ textAlign: "center" }}>{index + 1}</td>
                  <td style={{ textAlign: "start" }}>{item.branches}</td>
                  <td style={{ textAlign: "start" }}>{item.branch_url}</td>
                  <td style={{ textAlign: "start" }}>{item.backend_url}</td>
                  <td style={{ textAlign: "center" }}>
                    <span
                      onClick={() => middleUrlFunc(item.branch_url)}
                      style={{ cursor: "pointer" }}
                      className="svg-icon svg-icon-md svg-icon-primary"
                    >
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Devices/Display2.svg"
                        )}
                      />
                    </span>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <span
                      onClick={() => middleEditFunc(item, item.id)}
                      style={{ cursor: "pointer" }}
                      className="svg-icon svg-icon-md svg-icon-primary"
                    >
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Communication/Write.svg"
                        )}
                      />
                    </span>
                  </td>

                  <td style={{ textAlign: "center" }}>
                    <span
                      onClick={() => deleteBranchUrl(item.id)}
                      style={{ cursor: "pointer" }}
                      className="svg-icon svg-icon-md svg-icon-danger"
                    >
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/General/Trash.svg"
                        )}
                      />
                    </span>
                  </td>
                </tr>
              </>
            ))}
          </tbody>
        </Table>

        <div className="row justify-content-center">
          {loading && <span className="spinner-border"></span>}
        </div>

        <Modal show={addModal} size="lg">
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Add New Branch
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div>
              <div className="row">
                <div className="col-md-6">
                  <label>
                    <b>Branch Name</b> <span style={{ color: "red" }}>*</span>
                  </label>

                  <input
                    placeholder="Enter Branch Name"
                    className="form-control"
                    onChange={(e) => setBranchName(e.target.value)}
                  />
                </div>

                <div className="col-md-6">
                  <label>
                    <b>Branch Address</b>{" "}
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    className="form-control"
                    onChange={(e) => setBranchAddress(e.target.value)}
                    placeholder="Enter Branch Url Address"
                  />
                </div>

                <div className="col-md-6 mt-4">
                  <label>
                    <b>Backend Url</b> <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    className="form-control"
                    onChange={(e) => setBackendUrl(e.target.value)}
                    placeholder="Enter Backend Url Address"
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {addLoading ? (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : (
              <button
                className="btn btn-success button_blue"
                onClick={handleSubmit}
              >
                Add Branch
              </button>
            )}

            <button
              className="btn btn-danger btn_width"
              onClick={() => setaddModal(false)}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>

        <Modal show={deleteModal}>
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Delete Branch
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {deleteLoading ? (
              <b style={{ color: "green" }}>
                Branch Deleting Please wait {` `}{" "}
                <span className="spinner-border"></span>
              </b>
            ) : (
              <>Are you sure you want to delete?</>
            )}
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-danger btn_width"
              onClick={DeleteBranchUrlHandler}
            >
              Delete
            </button>
            <button
              className="btn  btn-primary btn_width"
              onClick={() => setdeleteModal(false)}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>

        <Modal show={editModal} size="lg">
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Update Branch
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div className="row">
                <div className="col-md-6">
                  <label>
                    <b>Branch Name</b> <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    placeholder="Enter Branch Name"
                    className="form-control"
                    onChange={(e) => setEditBranchName(e.target.value)}
                    defaultValue={editBranchName}
                  />
                </div>

                <div className="col-md-6">
                  <label>
                    <b>Branch Address</b>{" "}
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    className="form-control"
                    onChange={(e) => setEditBranchAddress(e.target.value)}
                    placeholder="Enter Branch Address"
                    defaultValue={editBranchAddress}
                  />
                </div>

                <div className="col-md-6 mt-4">
                  <label>
                    <b>Backend Url</b> <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    className="form-control"
                    onChange={(e) => setEditBackendUrl(e.target.value)}
                    placeholder="Enter Backend Url"
                    defaultValue={editBackendUrl}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {addLoading ? (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : (
              <button
                className="btn btn-success button_blue"
                onClick={handleUpdate}
              >
                Update
              </button>
            )}

            <button
              className="btn btn-danger btn_width"
              onClick={() => seteditModal(false)}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>
      </Paper>
    </div>
  );
};

export default BranchesUrl;
