import React, { useEffect, useRef, useState } from "react";
import { Table } from "react-bootstrap";
import ReactToPrint from "react-to-print";
import "./paySalaryPrint.css";
import { baseUrl } from "../../../../services/config";
import { upperCase } from "lodash";
const PaySalaryModal = ({
  show,
  paidStaff,
  paySalaryData,
  bankDetail,
  showCheque,
  grandTotal,
  selecteddate,
}) => {
  // console.log(bankDetail);
  console.log("paid staff", paidStaff);
  const pageStyle = `
  @page {
    size: legal portrait !important;
    margin-bottom: 1in;
  }
  `;

  let authtoken = localStorage.getItem("userToken");
  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);
  const [directorSign, setDirectorSign] = useState(0);

  useEffect(() => {
    getSettingData();
  }, []);

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload);
        setUnitName(upperCase(data.payload.settings.unit_name));
        setIcon(data.payload.settings.logo_file);
        setDirectorSign(data.payload.settings.director_sign);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //for print
  const [printloading, setprintloading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");
  const componentRef = useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);

  //for print
  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setprintloading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setprintloading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setprintloading, setText]);

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);
  const changeDate1 = () => {
    var yourDate = new Date(selecteddate);
    var months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    var year = yourDate.getFullYear();
    var monthIndex = yourDate.getMonth();

    var formattedDate = months[monthIndex] + ", " + year;

    return formattedDate;
  };
  const formatdate2 = () => {
    const date = new Date();

    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();

    const formattedDate = `${day}/${month}/${year}`;

    return formattedDate;
  };
  function inWords(num) {
    let a = [
      "",
      "one ",
      "two ",
      "three ",
      "four ",
      "five ",
      "six ",
      "seven ",
      "eight ",
      "nine ",
      "ten ",
      "eleven ",
      "twelve ",
      "thirteen ",
      "fourteen ",
      "fifteen ",
      "sixteen ",
      "seventeen ",
      "eighteen ",
      "nineteen ",
    ];
    let b = [
      "",
      "",
      "twenty",
      "thirty",
      "forty",
      "fifty",
      "sixty",
      "seventy",
      "eighty",
      "ninety",
    ];
    if ((num = num.toString()).length > 9) return "overflow";
    let n = ("000000000" + num)
      .substr(-9)
      .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) return;
    let str = "";
    str +=
      n[1] != 0
        ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "crore "
        : "";
    str +=
      n[2] != 0
        ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "lac "
        : "";
    str +=
      n[3] != 0
        ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "thousand "
        : "";
    str +=
      n[4] != 0
        ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "hundred "
        : "";
    str +=
      n[5] != 0
        ? (str != "" ? "and " : "") +
          (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]]) +
          "only "
        : "";
    console.log(str);
    return str;
  }

  return (
    <>
      <div>
        <Table>
          <thead>
            <tr>
              <th style={{ fontSize: "medium" }}>
                Bank: {bankDetail.bank_name}
              </th>
              <th style={{ fontSize: "medium" }}>
                Branch: {bankDetail.bank_branch}
              </th>
              <th style={{ fontSize: "medium" }}>Cheque No: {showCheque}</th>
            </tr>
          </thead>
        </Table>
      </div>
      <Table responsive>
        <thead>
          <tr>
            <th>ID</th>
            <th style={{ textAlign: "center" }}>Code</th>
            <th style={{ textAlign: "center" }}>Name</th>
            <th style={{ textAlign: "center" }}>Father name</th>
            <th style={{ textAlign: "center" }}>Cnic No</th>
            <th style={{ textAlign: "center" }}>Account No</th>
            <th style={{ textAlign: "center" }}>Net Pay</th>
          </tr>
        </thead>
        <tbody>
          {paidStaff.map((staff, index) => {
            return staff.map((item) => (
              <tr key={item.id}>
                <td>{item.employee.id}</td>
                <td>{item.employee.emp_code}</td>
                <td>{item.employee.full_name}</td>
                <td>{item.employee.father_name}</td>
                <td>{item.employee.cnic_no}</td>
                <td>{item.employee.account_no}</td>
                <td>{item.net_pay}</td>
              </tr>
            ));
          })}
        </tbody>
      </Table>
      {/* </Paper> */}
      <div style={{ justifyContent: "flex-end" }} className="row">
        <ReactToPrint
          documentTitle={`Pay Salary`}
          pageStyle={pageStyle}
          onAfterPrint={handleAfterPrint}
          onBeforeGetContent={handleOnBeforeGetContent}
          onBeforePrint={handleBeforePrint}
          removeAfterPrint={true}
          trigger={() => (
            <button
              // onClick={() => console.log("dfsdfsdfd")}
              disabled={printloading ? true : false}
              style={{ marginLeft: "10px" }}
              className="btn btn-primary w-90px mx-3"
            >
              {printloading ? <span>Printing..</span> : "Print"}
            </button>
          )}
          content={() => componentRef.current}
        />

        <button
          onClick={(e) => {
            e.preventDefault();
            show(false);
          }}
          className="btn btn-danger w-90px"
        >
          CLOSE
        </button>
      </div>

      <div
        ref={componentRef}
        id="printSection"
        style={{ visibility: "hidden" }}
        className="tablePrint"
      >
        <div className="p-3" style={{ display: "flex" }}>
          <img
            // className='col-md-2'
            style={{ height: 110, width: 120 }}
            src={icon}
            alt="logo"
          />

          <div className="col-md-8" style={{ paddingTop: "5px" }}>
            <h1>{unitName}</h1>
            <p className="paybodyFont nextmoreFont">
              <b>PAY SHEET FOR THE MONTH OF {upperCase(changeDate1())}</b>
            </p>
            <div
              className="cheqanddated"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div className="text-end">
                <b className="paybodyFont nextmoreFont">Cheque No:-</b>
                &nbsp;&nbsp;&nbsp;
                <span className="paybodyFont  nextmoreFont">{showCheque}</span>
              </div>

              <div className="text-center">
                <b className="paybodyFont"></b>&nbsp;&nbsp;&nbsp;
                <span className="paybodyFont nextmoreFont">
                  <b>{paySalaryData[0]?.campus?.name}</b>
                </span>
              </div>
              <div className="text-end">
                <b className="paybodyFont nextmoreFont">Dated:-</b>{" "}
                &nbsp;&nbsp;&nbsp;
                <span className="paybodyFont nextmoreFont">
                  {formatdate2()}
                </span>
              </div>
            </div>
          </div>
        </div>

        <table style={{ width: "100%", marginTop: "10px" }}>
          <thead>
            <tr>
              <td
                style={{
                  textAlign: "center",
                  border: "1px solid black",
                  color: "black",
                  fontSize: "18px",
                }}
              >
                <b>SNo.</b>
              </td>
              <td
                style={{
                  textAlign: "center",
                  border: "1px solid black",
                  color: "black",
                  fontSize: "18px",
                }}
              >
                <b>CODE</b>
              </td>
              <td
                style={{
                  textAlign: "start",
                  border: "1px solid black",
                  paddingLeft: "5px",
                  color: "black",
                  fontSize: "18px",
                }}
              >
                <b>EMPLOYEE NAME</b>
              </td>
              <td
                style={{
                  textAlign: "center",
                  border: "1px solid black",
                  color: "black",
                  fontSize: "18px",
                }}
              >
                <b>PAID</b>
              </td>
              <td
                style={{
                  textAlign: "center",
                  border: "1px solid black",
                  paddingLeft: "5px",
                  color: "black",
                  fontSize: "18px",
                }}
              >
                <b>ACCOUNT NO.</b>
              </td>
              <td
                style={{
                  textAlign: "center",
                  border: "1px solid black",
                  paddingLeft: "5px",
                  color: "black",
                  fontSize: "18px",
                }}
              >
                <b>SIGNATURE</b>
              </td>
            </tr>
          </thead>
          <tbody>
            {paidStaff.map((staff, index) => {
              return (
                <>
                  {staff.map((item, i) => (
                    <tr key={i}>
                      <td
                        style={{
                          textAlign: "center",
                          border: "1px solid black",
                          color: "black",
                          fontSize: "16px",
                        }}
                      >
                        {i + 1}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          border: "1px solid black",
                          color: "black",
                          fontSize: "16px",
                        }}
                      >
                        {item.employee.emp_code}
                      </td>
                      <td
                        style={{
                          textAlign: "start",
                          border: "1px solid black",
                          paddingLeft: "5px",
                          color: "black",
                          fontSize: "16px",
                        }}
                      >
                        {item.employee.full_name}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          border: "1px solid black",
                          color: "black",
                          fontSize: "16px",
                        }}
                      >
                        {parseInt(item.net_pay)}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          border: "1px solid black",
                          paddingLeft: "5px",
                          color: "black",
                          fontSize: "16px",
                        }}
                      >
                        {item.employee.account_no}
                      </td>
                      <td
                        style={{
                          textAlign: "start",
                          width: "150px",
                          border: "1px solid black",
                          paddingLeft: "5px",
                          color: "black",
                          fontSize: "16px",
                        }}
                      ></td>
                    </tr>
                  ))}
                </>
              );
            })}
          </tbody>
        </table>
        <div className="totalOfPaidSalary">
          <div className="d-flex">
            <p>
              <b>In Words:</b>
            </p>
            <p style={{ paddingLeft: "15px" }}>
              {" "}
              {inWords(parseInt(grandTotal))}
            </p>
          </div>
          <div className="d-flex">
            <p>
              <b>Total:</b>
            </p>
            <p style={{ paddingLeft: "15px" }}> {grandTotal}</p>
          </div>
        </div>
        <div
          className=""
          style={{
            marginTop: "80px",
            display: "flex",
            justifyContent: "space-between",
            width: "70%",
          }}
        >
          <div className="">
            <p>
              <b
                style={{
                  color: "black",
                  fontSize: "18px",
                  paddingRight: "30px",
                  paddingLeft: "30px",
                  borderTop: "1px solid black",
                }}
              >
                Accountant
              </b>
            </p>
          </div>
          <div className="" style={{ marginLeft: "50px" }}>
            {directorSign == 0 ? (
              <p>
                <b
                  style={{
                    color: "black",
                    fontSize: "18px",
                    paddingRight: "30px",
                    paddingLeft: "30px",
                    borderTop: "1px solid black",
                  }}
                >
                  Chief Executive / Principal
                </b>
              </p>
            ) : (
              <p>
                <b
                  style={{
                    color: "black",
                    fontSize: "18px",
                    paddingRight: "30px",
                    paddingLeft: "30px",
                    borderTop: "1px solid black",
                  }}
                >
                  Director / Principal
                </b>
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PaySalaryModal;
