import React, { useState, useEffect } from "react";
import { baseUrl } from "../../../services/config";
// import { useSelector } from "react-redux";
import { useAlert } from "react-alert";
import SVG from "react-inlinesvg";
import { Table, Modal, Spinner } from "react-bootstrap";
import { Paper } from "@material-ui/core";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

const BranchesUsers = () => {
  const alert = useAlert();
  const authtoken = localStorage.getItem("userToken");
  const [branchUrlData, setBranchUrlData] = useState([]);
  const [branchUserData, setBranchUserData] = useState([]);
  const [singleUserData, setSingleUserData] = useState([]);
  //   const user = useSelector((state) => state.user.user);
  const [loading, setloading] = useState(false);
  const [addModal, setaddModal] = useState(false);
  const [headofficeBranchId, setHeadofficeBranchId] = useState("");
  const [userId, setUserId] = useState("");
  const [branchName, setBranchName] = useState("");
  const [userName, setUserName] = useState("");

  const [editHeadofficeBranchId, setEditHeadofficeBranchId] = useState("");
  const [editUserId, setEditUserId] = useState("");
  const [editBranchName, setEditBranchName] = useState("");
  const [editUserName, setEditUserName] = useState("");

  const [selectedId, setselectedId] = useState("");
  const [addLoading, setaddLoading] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState("");
  const [deleteModal, setdeleteModal] = useState(false);
  const [deleteLoading, setdeleteLoading] = useState(false);
  const [editModal, seteditModal] = useState(false);

  useEffect(() => {
    getBranchUrls();
    getBranchUsers();
  }, []);

  const getBranchUrls = async () => {
    fetch(`${baseUrl}/view-branch-url`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setBranchUrlData(data.payload);
      })
      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };

  const getBranchUsers = async () => {
    setloading(true);
    fetch(`${baseUrl}/branches-users`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata.success) {
          setBranchUserData(data.payload);
        } else {
          alert.error("No Branch Users Record Found");
        }
        setloading(false);
      })
      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };

  const handleSubmit = () => {
    if (userId === "" || headofficeBranchId === "") {
      alert.error("Please Select Headoffice Branch Id & User Id");
      return;
    }
    if (branchName === "") {
      alert.error("Enter Branch Name");
      return;
    }
    if (userName === "") {
      alert.error("Enter User Name");
      return;
    }
    const data = {
      headoffice_branch_id: headofficeBranchId,
      user_id: userId,
      branch_name: branchName,
      user_name: userName,
    };
    setaddLoading(true);
    fetch(`${baseUrl}/branches-users`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) =>
        res.json().then((data) => {
          if (data.metadata.success) {
            setaddLoading(false);
            setaddModal(false);
            getBranchUsers();
            alert.success("Branch User Added Successfully");
          } else {
            alert.error("Branch User Not Added");
            setaddLoading(false);
            setaddModal(false);
          }
        })
      )
      .catch((err) => {
        console.log(err);
        setaddLoading(false);
        setaddModal(false);
      });
  };

  const handleUpdate = () => {
    if (editUserId === "" || editHeadofficeBranchId === "") {
      alert.error("Please Select Headoffice Branch Id & User Id");
      return;
    }
    if (editBranchName === "") {
      alert.error("Enter Branch Name");
      return;
    }
    if (editUserName === "") {
      alert.error("Enter User Name");
      return;
    }
    const data = {
      headoffice_branch_id: editHeadofficeBranchId,
      user_id: editUserId,
      branch_name: editBranchName,
      user_name: editUserName,
    };

    setaddLoading(true);
    fetch(`${baseUrl}/branches-users/${selectedId}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) =>
        res.json().then((data) => {
          if (data.metadata.success) {
            alert.success("Branch User Updated Successfully");
            setaddLoading(false);
            seteditModal(false);
            getBranchUsers();
          } else {
            alert.error("Branches User Not Updated");
            setaddLoading(false);
            setaddModal(false);
          }
        })
      )
      .catch((err) => {
        console.log(err);
        setaddLoading(false);
        setaddModal(false);
      });
  };

  const deleteBranchUser = (id) => {
    setDeleteUserId(id);
    setdeleteModal(true);
  };

  const DeleteBranchUserHandler = () => {
    setdeleteLoading(true);
    fetch(`${baseUrl}/branches-users/${deleteUserId}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata.success) {
          setdeleteModal(false);
          alert.success("Branch User Deleted Successfully");
          setdeleteLoading(false);
          getBranchUsers();
        }
      })
      .catch((err) => {
        setdeleteModal(false);
        setdeleteLoading(false);
        console.log(err);
      });
  };

  const getEditUser = (userData, index) => {
    setselectedId(index);
    getSingleBranchUrl(userData?.headoffice_branch_id);
    setEditHeadofficeBranchId(userData?.headoffice_branch_id);
    setEditUserId(userData?.user_id);
    setEditBranchName(userData?.branch_name);
    setEditUserName(userData?.user_name);
    seteditModal(true);
  };

  const getSingleBranchUrl = (index) => {
    if (!Array.isArray(branchUrlData)) {
      console.error("Something Went Wrong!! branchUrlData must be an array");
      return;
    }
    const filteredData = branchUrlData.filter(
      (item) => item.id === parseInt(index)
    );
    getBranchAllUsers(filteredData[0]?.backend_url);
  };

  const getBranchAllUsers = async (branchUrl) => {
    const data = {
      backend_url: branchUrl,
    };
    const credentials = btoa(`${"dass_admin"}:${"ht959J=@G#K1-14"}`);

    await fetch(`${baseUrl}/get-branch-all-users`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Basic ${credentials}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        setSingleUserData(data.payload);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <Paper
        style={{ marginTop: "15px", padding: "15px", marginBottom: "15px" }}
      >
        <button className="btn btn-primary" onClick={() => setaddModal(true)}>
          Add Branch User{" "}
        </button>
        <br />
        <br />
        <Table>
          <thead>
            <tr className="text-center">
              <th style={{ textAlign: "center" }}>Sr.#</th>
              <th style={{ textAlign: "start" }}>Branch Name</th>
              <th style={{ textAlign: "start" }}>User Name</th>
              <th style={{ textAlign: "center" }}>Edit</th>
              <th style={{ textAlign: "center" }}>Delete</th>
            </tr>
          </thead>
          <tbody>
            {branchUserData.map((item, index) => (
              <>
                <tr key={item.id}>
                  <td style={{ textAlign: "center" }}>{index + 1}</td>
                  <td style={{ textAlign: "start" }}>{item.branch_name}</td>
                  <td style={{ textAlign: "start" }}>{item.user_name}</td>

                  <td style={{ textAlign: "center" }}>
                    <span
                      onClick={() => getEditUser(item, item.id)}
                      style={{ cursor: "pointer" }}
                      className="svg-icon svg-icon-md svg-icon-primary"
                    >
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Communication/Write.svg"
                        )}
                      />
                    </span>
                  </td>

                  <td style={{ textAlign: "center" }}>
                    <span
                      onClick={() => deleteBranchUser(item.id)}
                      style={{ cursor: "pointer" }}
                      className="svg-icon svg-icon-md svg-icon-danger"
                    >
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/General/Trash.svg"
                        )}
                      />
                    </span>
                  </td>
                </tr>
              </>
            ))}
          </tbody>
        </Table>

        <div className="row justify-content-center">
          {loading && <span className="spinner-border"></span>}
        </div>

        <Modal show={addModal} size="lg">
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Add New Branch User
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div>
              <div className="row">
                <div className="col-md-6">
                  <label>
                    <b>Select Headoffice Branch</b>
                    <span style={{ color: "red", fontSize: 12 }}>*</span>
                  </label>
                  <select
                    onChange={(e) => {
                      if (e.target.value !== "") {
                        const selectedObject = branchUrlData.find(
                          (option) => option.id === parseInt(e.target.value)
                        );
                        setBranchName(selectedObject?.branches);
                      } else {
                        setBranchName("");
                      }
                      setHeadofficeBranchId(e.target.value);
                      getSingleBranchUrl(e.target.value);
                    }}
                    className="form-select h-40px"
                    value={headofficeBranchId}
                    aria-label="Default select example"
                  >
                    <option value="">Select Branch</option>
                    {branchUrlData.map((branch, index) => (
                      <option key={index} value={branch.id}>
                        {branch?.branches}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-md-6">
                  <label>
                    <b>Select Headoffice User</b>
                    <span style={{ color: "red", fontSize: 12 }}>*</span>
                  </label>
                  <select
                    onChange={(e) => {
                      if (e.target.value !== "") {
                        const selectedObject = singleUserData.find(
                          (option) => option.id === parseInt(e.target.value)
                        );
                        setUserName(
                          selectedObject?.first_name +
                            " " +
                            selectedObject?.last_name
                        );
                      } else {
                        setUserName("");
                      }
                      setUserId(e.target.value);
                    }}
                    className="form-select h-40px"
                    value={userId}
                    aria-label="Default select example"
                  >
                    <option value="">Select User</option>
                    {singleUserData.map((userData, index) => (
                      <option key={index} value={userData.id}>
                        {userData?.first_name + " " + userData?.last_name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="row mt-6">
                <div className="col-md-6">
                  <label>
                    <b>Branch Name</b> <span style={{ color: "red" }}>*</span>
                  </label>

                  <input
                    className="form-control"
                    defaultValue={branchName}
                    value={branchName}
                    onChange={(e) => setBranchName(e.target.value)}
                    placeholder="Enter Branch Name"
                  />
                </div>

                <div className="col-md-6">
                  <label>
                    <b>User Name</b> <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    className="form-control"
                    defaultValue={userName}
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                    placeholder="Enter User Name"
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {addLoading ? (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : (
              <button
                className="btn btn-success button_blue"
                onClick={handleSubmit}
              >
                Add Branch
              </button>
            )}

            <button
              className="btn btn-danger btn_width"
              onClick={() => setaddModal(false)}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>

        <Modal show={deleteModal}>
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Delete Branch User
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {deleteLoading ? (
              <b style={{ color: "green" }}>
                Branch Deleting Please wait {` `}{" "}
                <span className="spinner-border"></span>
              </b>
            ) : (
              <>Are you sure you want to delete branch user?</>
            )}
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-danger btn_width"
              onClick={DeleteBranchUserHandler}
            >
              Delete
            </button>
            <button
              className="btn  btn-primary btn_width"
              onClick={() => setdeleteModal(false)}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>

        <Modal show={editModal} size="lg">
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Update Branch User
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div className="row">
                <div className="col-md-6">
                  <label>
                    <b>Select Headoffice Branch</b>
                    <span style={{ color: "red", fontSize: 12 }}>*</span>
                  </label>
                  <select
                    onChange={(e) => {
                      if (
                        e.target.value !== "" ||
                        editHeadofficeBranchId !== ""
                      ) {
                        const selectedObject = branchUrlData.find(
                          (option) =>
                            option.id ===
                            parseInt(e.target.value ?? editHeadofficeBranchId)
                        );
                        setEditBranchName(selectedObject?.branches);
                      } else {
                        setEditBranchName("");
                      }
                      setEditHeadofficeBranchId(e.target.value);
                      getSingleBranchUrl(e.target.value);
                    }}
                    className="form-select h-40px"
                    value={editHeadofficeBranchId}
                    aria-label="Default select example"
                  >
                    <option value="">Select Branch</option>
                    {branchUrlData.map((branch, index) => (
                      <option key={index} value={branch.id}>
                        {branch?.branches}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-md-6">
                  <label>
                    <b>Select Headoffice User</b>
                    <span style={{ color: "red", fontSize: 12 }}>*</span>
                  </label>
                  <select
                    onChange={(e) => {
                      if (e.target.value !== "" || editUserId !== "") {
                        const selectedObject = singleUserData.find(
                          (option) =>
                            option.id === parseInt(e.target.value ?? editUserId)
                        );
                        setEditUserName(
                          selectedObject?.first_name +
                            " " +
                            selectedObject?.last_name
                        );
                      } else {
                        setEditUserName("");
                      }
                      setEditUserId(e.target.value);
                    }}
                    className="form-select h-40px"
                    value={editUserId}
                    aria-label="Default select example"
                  >
                    <option value="">Select User</option>
                    {singleUserData.map((userData, index) => (
                      <option key={index} value={userData.id}>
                        {userData?.first_name + " " + userData?.last_name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="row mt-6">
                <div className="col-md-6">
                  <label>
                    <b>Branch Name</b> <span style={{ color: "red" }}>*</span>
                  </label>

                  <input
                    className="form-control"
                    defaultValue={editBranchName}
                    value={editBranchName}
                    onChange={(e) => setEditBranchName(e.target.value)}
                    placeholder="Enter Branch Name"
                  />
                </div>

                <div className="col-md-6">
                  <label>
                    <b>User Name</b> <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    className="form-control"
                    defaultValue={editUserName}
                    value={editUserName}
                    onChange={(e) => setEditUserName(e.target.value)}
                    placeholder="Enter User Name"
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {addLoading ? (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : (
              <button
                className="btn btn-success button_blue"
                onClick={handleUpdate}
              >
                Update
              </button>
            )}

            <button
              className="btn btn-danger btn_width"
              onClick={() => seteditModal(false)}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>
      </Paper>
    </div>
  );
};

export default BranchesUsers;
